import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import SortablePlayerListItem from "./SortablePlayerListItem";
import React, { FC } from "react";
import { Player } from "../../../../types";
import { UniqueIdentifier, useDroppable } from "@dnd-kit/core";
import { Box, List, Typography } from "@mui/material";
import { DragHandle } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

type SortablePlayersListProps = {
  items: UniqueIdentifier[];
  players: Player[];
  id: UniqueIdentifier;
  compact?: boolean;
};

const Empty = () => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        p: 2,
        minHeight: 150,
        height: "100%",
        display: "flex",
        alignItems: "center",
        flex: 1,
        justifyContent: "center",
      }}
    >
      <DragHandle sx={{ mr: 1 }} />
      <Typography variant="body1">{t("DRAG_PLAYERS_HERE")}</Typography>
    </Box>
  );
};

const SortablePlayersList: FC<SortablePlayersListProps> = ({
  items,
  players,
  id,
  compact,
}) => {
  const { setNodeRef } = useDroppable({
    id,
  });

  return (
    <List sx={{ height: "100%", flex: 1 }} disablePadding ref={setNodeRef}>
      {!players.length && <Empty />}
      <SortableContext items={items} strategy={verticalListSortingStrategy}>
        {players.map((player, _index) => {
          return (
            <React.Fragment key={player.documentId}>
              <SortablePlayerListItem compact={compact} {...player} />
            </React.Fragment>
          );
        })}
      </SortableContext>
    </List>
  );
};
export default SortablePlayersList;

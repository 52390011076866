import { useState } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { Dialog } from "@mui/material";
import Paper from "../../components/Paper/Paper";
import { usePlayers } from "../../providers/PlayersProvider";
import PlayerEditor from "../../components/PlayerEditor/PlayerEditor";
import { deletePlayer, updatePlayer } from "../../api/players";
import Confirmation from "../../components/Confirmation/Confirmation";
import { useTranslation } from "react-i18next";

function EditPlayer() {
  const { setSearch } = useOutletContext<{
    setSearch: (string: string) => void;
  }>();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const { players } = usePlayers();
  const { t } = useTranslation();

  const player = players?.find((player) => player.documentId === id);

  if (!id) {
    return null;
  }

  return (
    <>
      <Confirmation
        title={t("DELETE_PLAYER")}
        description={t("DELETE_PLAYER_CONFIRMATION")}
        open={open}
        onClose={() => setOpen(false)}
        onConfirm={async () => {
          setLoading(true);
          try {
            await deletePlayer(id);
          } catch (error) {
            console.log(error);
          } finally {
            setLoading(false);
            navigate("/players");
            setSearch("");
          }
        }}
      />
      <Dialog
        open
        PaperProps={{
          loading,
        }}
        // eslint-disable-next-line
        // @ts-ignore
        PaperComponent={Paper}
        onClose={() => {
          navigate("/players");
        }}
      >
        <PlayerEditor
          edit
          initialValues={{
            name: player?.name,
            position: player?.position,
            rank: player?.rank,
            entries: player?.entries || 0,
            temporary: player?.temporary || false,
          }}
          loading={loading}
          onDelete={() => {
            setOpen(true);
          }}
          onSubmit={async (values) => {
            setLoading(true);
            try {
              await updatePlayer(id, values);
            } catch (error) {
              console.log(error);
            } finally {
              setLoading(false);
              navigate("/players");
            }
          }}
        />
      </Dialog>
    </>
  );
}

export default EditPlayer;

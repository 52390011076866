import { FormControlLabel, Switch } from "@mui/material";
import React, { ChangeEvent, FC } from "react";

type SwitchFieldProps = {
  field: any;
  form: any;
  helperText?: string;
  error?: boolean;
  label?: string;
};

const SwitchField: FC<SwitchFieldProps> = ({
  field,
  form,
  helperText,
  label,
  ...rest
}) => {
  const onChange = (e: ChangeEvent<{ value: string }>) => {
    form.setFieldValue(field.name, !field.value);
  };

  return (
    <FormControlLabel
      control={<Switch checked={field.value} onChange={onChange} {...rest} />}
      label={label}
    />
  );
};

export default SwitchField;

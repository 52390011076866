import { List } from "@mui/material";
import React, { FC } from "react";
import { Player } from "../../types";
import PlayerListItem from "./PlayerListItem";
import PlayerListItemLoader from "./PlayerListItemLoader";

type PlayersListProps = {
  players: Player[] | undefined;
  onPlayerClick?: (player: Player) => void;
  loading?: boolean;
  compact?: boolean;
  showEntries?: boolean;
  showRank?: boolean;
};

const PlayersList: FC<PlayersListProps> = ({
  players,
  onPlayerClick,
  loading,
  compact,
  showEntries,
  showRank,
}) => {
  return (
    <List disablePadding>
      {loading &&
        new Array(10)
          .fill(0)
          .map((_, index) => (
            <PlayerListItemLoader compact={compact} key={`loader-${index}`} />
          ))}
      {players?.map((player) => (
        <PlayerListItem
          showRank={showRank}
          showEntries={showEntries}
          compact={compact}
          key={player.documentId + "_innerlist"}
          {...player}
          showDivider={
            players[players.length - 1].documentId !== player.documentId
          }
          onClick={() => onPlayerClick?.(player)}
        />
      ))}
    </List>
  );
};

export default PlayersList;

import React, { FC } from "react";
import { getEntriesText } from "../../utils/getEntriesText";
import { useTranslation } from "react-i18next";
import { Chip } from "@mui/material";

const mapChipColorToEntries = (entries: number) => {
  if (entries > 0) {
    return "success";
  } else if (entries < 0) {
    return "error";
  }

  return "warning";
};

type EntriesChipProps = {
  entries: number;
};

export const EntriesChip: FC<EntriesChipProps> = ({ entries }) => {
  const { t } = useTranslation();
  return (
    <Chip
      size="small"
      variant="outlined"
      color={mapChipColorToEntries(entries)}
      label={getEntriesText(entries, t)}
    />
  );
};

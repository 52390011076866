import { FC } from "react";
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { he } from "date-fns/locale";
import { useTranslation } from "react-i18next";

type DatePickerProps = {
  field: any;
  form: any;
  helperText?: string;
  error?: string;
};

const DatePicker: FC<DatePickerProps> = ({
  field,
  form,
  helperText,
  error,
  ...rest
}) => {
  const { t } = useTranslation();
  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={he}
      localeText={{
        datePickerToolbarTitle: t("SELECT_DATE"),
      }}
    >
      <MuiDatePicker
        helperText={error || helperText}
        sx={{ width: "100%" }}
        size="small"
        variant="outlined"
        format="dd MMM yyyy"
        disablePast
        error={error}
        {...field}
        value={new Date(field.value)}
        onChange={(val) => form.setFieldValue(field.name, val?.toISOString())}
        {...rest}
      />
    </LocalizationProvider>
  );
};

export default DatePicker;

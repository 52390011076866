import { Box, CircularProgress } from "@mui/material";

const LogoLoading = () => (
  <Box sx={{ position: "relative", fontSize: 64 }}>
    <img src="/logo.svg" alt="logo" width="64px" height="64px" />
    <CircularProgress
      size={64 + 12}
      sx={{
        position: "absolute",
        top: 0,
        left: -6,
        color: (theme) => theme.palette.primary.main,
      }}
    />
  </Box>
);

export default LogoLoading;

import { formatDate as FNS } from "date-fns";
import { he } from "date-fns/locale";

export const FORMATS = {
  LONG: "EEEE הdd בMMM yyyy",
  SHORT: "dd/MM/yyyy",
};

const formatDate = (date: Date, format = FORMATS.LONG) => {
  try {
    return FNS(date, format, {
      locale: he,
    });
  } catch {
    return "";
  }
};

export default formatDate;

import { UniqueIdentifier } from "@dnd-kit/core";
import { Player } from "../../types";
import { FC, ReactNode, useMemo } from "react";
import { Box, Rating, styled, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import calculateTeamAverageScore from "../../utils/calculateTeamAverageScore";
import { Star } from "@mui/icons-material";
import { Jersey } from "../Icons/Jersey";
import { red, yellow } from "@mui/material/colors";

type TeamOverviewProps = {
  players?: Player[];
  containerId: UniqueIdentifier;
  children?: ReactNode;
};

export const TeamOverviewHeader = styled(Box)(({ theme }) => ({
  borderBottom: "2px solid",
  borderColor: theme.palette.primary.dark,
  height: 64,
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
}));

export const mapTeamIdToColor: Record<number, string> = {
  1: "white",
  2: yellow[500],
  3: red[700],
};

export const Team: FC<TeamOverviewProps> = ({
  players,
  containerId,
  children,
}) => {
  const id = parseInt(containerId.toString().split("-")[1]);
  const { t } = useTranslation();

  const averageRank = useMemo(
    () =>
      players ? parseFloat(calculateTeamAverageScore(players).toFixed(2)) : 0,
    [players]
  );

  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        borderRight: "1px solid",
        flexDirection: "column",
        borderColor: (t) => t.palette.divider,
        "&:last-child": {
          borderRight: 0,
        },
        px: 1,
      }}
    >
      <TeamOverviewHeader sx={{ borderColor: mapTeamIdToColor[id] }}>
        <Jersey fill={mapTeamIdToColor[id]} sx={{ marginRight: 1 }} />
        <Typography variant="h6">
          {t("TEAM_TITLE", {
            index: id,
          })}
        </Typography>
      </TeamOverviewHeader>
      <Box flex={1} pt={2}>
        {children}
      </Box>
      {players?.length ? (
        <>
          <TeamOverviewHeader>
            <Typography variant="h6">{t("AVERAGE_RANK")}</Typography>
          </TeamOverviewHeader>
          <Box display="flex" alignItems="center" p={2} justifyContent="center">
            <Rating
              name="text-feedback"
              value={averageRank / 2}
              readOnly
              precision={0.5}
              emptyIcon={<Star fontSize="inherit" />}
              size="large"
            />
            <Typography variant="h6" ml={1} fontWeight={800} color="primary">
              {averageRank || "-"}
            </Typography>
          </Box>
        </>
      ) : null}
    </Box>
  );
};

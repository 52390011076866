import { collection, query, where } from "firebase/firestore";
import { FC, ReactNode, createContext, useContext, useMemo } from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import { db } from "../hooks/firebase";
import collectionToArray from "../utils/collectionToArray";
import { ManagerSettings } from "../types";
import { useUser } from "./UserProvider";

export type ManagerSettingsContextType = {
  managerSettings?: ManagerSettings;
  loading?: boolean;
};

const ManagerSettingsContext = createContext<ManagerSettingsContextType>({});

export const useManagerSettings = () => {
  return useContext(ManagerSettingsContext);
};

const DEFAULT_MANAGER_ID = "a4fJfMdBTSQJPayTWNaGmC8v1uf2";

const ManagerSettingsProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { user } = useUser();

  const [managerSettingsSnapshot, loading] = useCollection(
    query(
      collection(db, "managerSettings"),
      where("managerId", "==", user?.uid || DEFAULT_MANAGER_ID)
    ),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  const managerSettings = useMemo(
    () =>
      loading
        ? undefined
        : collectionToArray<ManagerSettings>({
            collection: managerSettingsSnapshot,
          })[0],
    [managerSettingsSnapshot, loading]
  );

  return (
    <ManagerSettingsContext.Provider value={{ managerSettings, loading }}>
      {children}
    </ManagerSettingsContext.Provider>
  );
};

export default ManagerSettingsProvider;

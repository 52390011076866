export const apiSuccess = <T>(data?: T) => {
  return {
    success: true,
    data,
  };
};

export const apiError = <T>(error: T) => {
  console.error(error);
  return {
    success: false,
    error,
  };
};

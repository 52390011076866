import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CustomPaper from "../CustomPaper/CustomPaper";
import { useTranslation } from "react-i18next";

type ConfirmationProps = {
  open: boolean;
  onClose: () => void;
  title: string;
  description: string;
  onConfirm?: () => void;
  loading?: boolean;
};

const Confirmation: React.FC<ConfirmationProps> = ({
  open,
  onClose,
  title,
  description,
  onConfirm,
  loading,
}) => {
  const { t } = useTranslation();

  return (
    <Dialog
      /* @ts-ignore */
      PaperComponent={CustomPaper}
      PaperProps={{ loading }}
      open={open}
      onClose={onClose}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{description}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onClose}>
          {t("CANCEL")}
        </Button>
        <Button onClick={onConfirm}>{t("CONFIRM")}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default Confirmation;

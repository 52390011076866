import { Card, CardActionArea, Divider, TypographyProps } from "@mui/material";
import { FC, ReactNode } from "react";
import CustomCardHeader from "./CustomCardHeader";

type CustomCardProps = {
  stepNumber?: number;
  title: string;
  titleVariant?: TypographyProps["variant"];
  children?: React.ReactNode;
  divider?: boolean;
  borderColor?: string;
  icon?: ReactNode;
  button?: boolean;
  onClick?: () => void;
  secondaryText?: ReactNode;
  bgcolor?: string;
  headerBorder?: boolean;
  height?: number | string;
};

const CustomCard: FC<CustomCardProps> = ({
  icon,
  title,
  children,
  divider = true,
  borderColor,
  button,
  onClick,
  secondaryText,
  bgcolor,
  titleVariant,
  headerBorder = true,
  height,
}) => {
  const Wrapper = !button ? "div" : CardActionArea;
  return (
    <Card sx={{ bgcolor, width: "100%", height }}>
      <Wrapper disabled={!button} onClick={onClick}>
        <CustomCardHeader
          icon={icon}
          borderColor={borderColor}
          secondaryText={secondaryText}
          title={title}
          titleVariant={titleVariant}
          border={headerBorder}
        />
        {divider && <Divider />}
        {children}
      </Wrapper>
    </Card>
  );
};

export default CustomCard;

import { FC, useEffect } from "react";
import { useHeader } from "../../providers/HeaderProvider";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Switch,
} from "@mui/material";
import { DarkMode, LightMode, Savings } from "@mui/icons-material";
import { Outlet, useNavigate } from "react-router-dom";
import { useSystemSettings } from "../../providers/SystemSettingsProvider";
import { useTranslation } from "react-i18next";
import Page from "../../components/Page/Page";

const Settings: FC = () => {
  const { t } = useTranslation();
  const { setTitle } = useHeader();
  const { systemSettings, updateSystemSettings } = useSystemSettings();
  const navigate = useNavigate();

  useEffect(() => {
    setTitle(t("SETTINGS"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page>
      <List
        subheader={
          <ListSubheader sx={{ background: "none" }}>
            {t("TEAMS")}
          </ListSubheader>
        }
      >
        <ListItem
          button
          divider
          onClick={() => navigate("/settings/edit/budget")}
        >
          <ListItemIcon>
            <Savings />
          </ListItemIcon>
          <ListItemText
            primary={t("EDIT_BUDGET")}
            secondary={t("EDIT_BUDGET_HELPER_TEXT")}
          />
        </ListItem>
      </List>
      <List
        subheader={
          <ListSubheader sx={{ background: "none" }}>
            {t("SYSTEM")}
          </ListSubheader>
        }
      >
        <ListItem divider>
          <ListItemIcon>
            {systemSettings.darkMode ? <DarkMode /> : <LightMode />}
          </ListItemIcon>
          <ListItemText
            primary={t("DARK_MODE")}
            secondary={t("DARK_MODE_HELPER_TEXT")}
          />
          <Switch
            edge="end"
            checked={systemSettings.darkMode}
            onChange={() =>
              updateSystemSettings({ darkMode: !systemSettings.darkMode })
            }
          />
        </ListItem>
      </List>
      <Outlet />
    </Page>
  );
};

export default Settings;

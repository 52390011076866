import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useHeader } from "../../providers/HeaderProvider";
import {
  NUMBER_OF_PLAYERS_PER_TEAM,
  NUMBER_OF_TEAMS_PER_DRAFT,
} from "../../consts";
import { saveMatch } from "../../api/matches";
import { useUser } from "../../providers/UserProvider";
import { useNavigate } from "react-router-dom";
import MatchEditor from "../../components/MatchEditor/MatchEditor";
import { SelectedPlayers } from "../../types";

const defaultShitForTests = [
  { label: "ירין", value: "xvLYkpTFJjTzY3CfOJXb" },
  { label: "שחקן לבדיקות - ירין לא למחוק", value: "zYF2VxsL3skilHHtsIrh" },
  { label: "אושר", value: "l0jPZtJfp4WaCPOFxA4f" },
  { label: "טל", value: "Ze2JLrX2YE16IFs6nxlc" },
  { label: "גבננאו", value: "tJakv1ISCoUxFNQSdo2u" },
  { label: "דני", value: "VdZGR6FONZjTeVww66we" },
  { label: "אורי", value: "jslgfmL8IB5lpOYOegIF" },
  { label: "אפי", value: "FXFTGTQx15PBqUWpcigN" },
  { label: "ג'ק", value: "rjfhSIfxcL9ZN6rXPNPJ" },
  { label: "בר א", value: "3u13BPOgGUPWGriRV7FS" },
  { label: "גורביץ", value: "LzwZZG9Ju7avoZrzV8oA" },
  { label: "זיו", value: "esd9GvwP2JJfX61KCbfr" },
  { label: "גלעד", value: "uRN4okTvBcVXNPxOZZ0F" },
  { label: "מאיר", value: "7tGetVRURE4n2lD47sbz" },
  { label: "כידן", value: "rlqbyoJP69dWNhKQ31bu" },
  { label: "אלי", value: "mr42dMbgoU3hgf7PXCTV" },
  { label: "אריאל", value: "Uodg71V0BKAUDESDBD2D" },
  { label: "אברהם", value: "sEy9rO6jB4soaLT5CTza" },
  { label: "אביב א", value: "5SPBmLTKoQuGEpx3nPrO" },
  { label: "אושר זוהר - מזמין חיצוני", value: "zGYNHNP2iS9fqYnBmCPf" },
  { label: "אופק", value: "SGo8j5h7CBHALypLMNGN" },
] as SelectedPlayers;

const initialValueTest = {
  settings: {
    date: new Date().toISOString(),
    time: new Date().toISOString(),
    location: "ראשון לציון",
    numberOfTeams: 3,
    numberOfPlayersPerTeam: 7,
  },
  selectedPlayers: defaultShitForTests,
  teams: undefined,
};

const initialValues = {
  settings: {
    date: new Date().toISOString(),
    time: new Date().toISOString(),
    location: undefined,
    numberOfTeams: NUMBER_OF_TEAMS_PER_DRAFT,
    numberOfPlayersPerTeam: NUMBER_OF_PLAYERS_PER_TEAM,
  },
  selectedPlayers: undefined,
  teams: undefined,
};

const CreateMatch = () => {
  const navigate = useNavigate();
  const [isSaving, setIsSaving] = useState(false);
  const { setTitle } = useHeader();
  const { user } = useUser();
  const { t } = useTranslation();

  useEffect(() => {
    setTitle(t("CREATE_MATCH_DAY"));
  }, [t, setTitle]);

  return (
    <MatchEditor
      loading={isSaving}
      initialValues={
        user?.email?.includes("matanassa") ? initialValueTest : initialValues
      }
      onSubmit={async (values) => {
        setIsSaving(true);
        try {
          const res = await saveMatch({
            managerId: user!.uid,
            matchDraft: values,
          });

          if (res.success && "data" in res) {
            navigate("/match/" + res.data!.matchId);
          }
        } catch (error) {
          console.error(error);
        } finally {
          setIsSaving(false);
        }
      }}
    />
  );
};

export default CreateMatch;

import { FC, useEffect } from "react";

type MatchEditorValidatorProps = {
  validateForm: () => void;
  activeStep: number;
};

const MatchEditorValidator: FC<MatchEditorValidatorProps> = ({
  validateForm,
  activeStep,
}) => {
  useEffect(() => {
    validateForm();
  }, [activeStep, validateForm]);

  return null;
};

export default MatchEditorValidator;

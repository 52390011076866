import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Dialog } from "@mui/material";
import Paper from "../../components/Paper/Paper";
import SettingsEditor, {
  ManagerSettingsDisplayType,
} from "../../components/ManagerSettingsEditor/ManagerSettingsEditor";
import {
  createManagerSettings,
  updateManagerSettings,
} from "../../api/managerSettings";
import { useManagerSettings } from "../../providers/ManagerSettingsProvider";
import { useUser } from "../../providers/UserProvider";

function EditManagerSettings() {
  const [updating, setUpdating] = useState(false);
  const navigate = useNavigate();
  const { display } = useParams();
  const { user } = useUser();

  const { managerSettings, loading } = useManagerSettings();

  if (!display) {
    return null;
  }

  return (
    <Dialog
      open
      PaperProps={{
        loading: updating || loading,
      }}
      // eslint-disable-next-line
      // @ts-ignore
      PaperComponent={Paper}
      onClose={() => navigate("/settings")}
    >
      <SettingsEditor
        display={display as ManagerSettingsDisplayType}
        initialValues={{
          budget: managerSettings?.budget || 0,
        }}
        loading={loading}
        // onDelete={() => deleteTransaction(id)}
        onSubmit={async (values) => {
          setUpdating(true);

          if (!managerSettings?.documentId && user) {
            try {
              await createManagerSettings(user?.uid, values);
            } catch (error) {
              console.log(error);
            } finally {
              setUpdating(false);
              navigate("/settings");
            }

            return;
          }

          try {
            await updateManagerSettings(managerSettings!.documentId, values);
          } catch (error) {
            console.log(error);
          } finally {
            setUpdating(false);
            navigate("/settings");
          }
        }}
      />
    </Dialog>
  );
}

export default EditManagerSettings;

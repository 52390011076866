import { addDoc, collection, doc, setDoc } from "firebase/firestore";
import { db } from "../hooks/firebase";
import { ManagerSettings } from "../types";
import { apiError, apiSuccess } from "./utils";

export const updateManagerSettings = async (
  documentId: string,
  managerSettings: Partial<ManagerSettings>
) => {
  const ref = doc(db, "managerSettings", documentId);

  try {
    await setDoc(
      ref,
      {
        ...managerSettings,
        lastUpdated: Date.now(),
      },
      { merge: true }
    );

    return apiSuccess();
  } catch (error) {
    return apiError(error);
  }
};

export const createManagerSettings = async (
  managerId: string,
  managerSettings: Partial<ManagerSettings>
) => {
  try {
    const res = await addDoc(collection(db, "managerSettings"), {
      managerId,
      ...managerSettings,
      createdAt: Date.now(),
      lastUpdated: Date.now(),
    });

    return apiSuccess({
      documentId: res.id,
    });
  } catch (error) {
    return apiError(error);
  }
};

import { useCollection } from "react-firebase-hooks/firestore";
import { and, collection, query, where } from "firebase/firestore";
import { useMemo } from "react";
import collectionToArray from "../utils/collectionToArray";
import { db } from "./firebase";
import { Match, MatchStatus } from "../types";
import { useUser } from "../providers/UserProvider";

const useMatches = () => {
  const { user } = useUser();

  const [matchesSnapshot, loading] = useCollection(
    query(collection(db, "matches"), where("managerId", "==", user?.uid)),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  const matches = useMemo(
    () =>
      loading
        ? []
        : collectionToArray<Match>({
            collection: matchesSnapshot,
          }).sort((a, b) => {
            return a.settings.date > b.settings.date ? 1 : -1;
          }),
    [matchesSnapshot, loading]
  );

  return { matches, loading };
};

export const useMatchesByStatus = (status: MatchStatus) => {
  const { user } = useUser();
  const [matchesSnapshot, loading] = useCollection(
    query(
      collection(db, "matches"),
      and(where("managerId", "==", user?.uid), where("status", "==", status))
    ),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  const matches = useMemo(
    () =>
      loading
        ? []
        : collectionToArray<Match>({
            collection: matchesSnapshot,
          }),
    [matchesSnapshot, loading]
  );

  return { matches, loading };
};

export default useMatches;

import { motion } from "framer-motion";
import { FC, ReactNode } from "react";
import {
  CircularProgress,
  Paper as MuiPaper,
  type PaperProps,
  useTheme,
} from "@mui/material";

type CustomPaperProps = {
  loading?: boolean;
  children: ReactNode;
} & PaperProps;

const Paper: FC<CustomPaperProps> = ({ loading, children, ...rest }) => {
  const theme = useTheme();

  return (
    <MuiPaper
      sx={{
        backgroundColor:
          theme.palette.mode === "dark" ? theme.palette.grey[900] : undefined,
        backgroundImage: "none",
      }}
      {...rest}
    >
      {loading && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 0.85 }}
          exit={{ opacity: 0 }}
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
            backgroundColor:
              theme.palette.mode === "dark"
                ? theme.palette.grey[900]
                : undefined,
            zIndex: 88888,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            opacity: 0.85,
            borderRadius: 4,
          }}
        >
          <CircularProgress size={24} />
        </motion.div>
      )}
      {children}
    </MuiPaper>
  );
};

export default Paper;

import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const Jersey = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg
      fill={props.fill}
      enable-background="new 0 0 32 32"
      height="32px"
      version="1.1"
      viewBox="0 0 32 32"
      width="32px"
      xmlSpace="preserve"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g id="ball" />
      <g id="wistle" />
      <g id="pitch" />
      <g id="goal" />
      <g id="socks" />
      <g id="shoe" />
      <g id="jersey" />
      <g id="bottle" />
      <g id="shorts" />
      <g id="corner" />
      <g id="winner" />
      <g id="trophy">
        <path d="M30.243,2.03l-4-1C26.163,1.01,26.082,1,26,1h-6c-0.552,0-1,0.448-1,1c0,1.654-1.346,3-3,3s-3-1.346-3-3   c0-0.552-0.448-1-1-1H6C5.918,1,5.837,1.01,5.757,1.03l-4,1C1.313,2.141,1,2.541,1,3v6c0,0.459,0.313,0.859,0.757,0.97L5,10.781V26   c0,0.552,0.448,1,1,1h20c0.552,0,1-0.448,1-1V10.781l3.243-0.811C30.688,9.859,31,9.459,31,9V3C31,2.541,30.688,2.141,30.243,2.03z    M29,8.219L25.757,9.03C25.313,9.141,25,9.541,25,10v15H7V10c0-0.459-0.313-0.859-0.757-0.97L3,8.219V3.781L6.123,3h4.978   C11.566,5.279,13.585,7,16,7s4.434-1.721,4.899-4h4.978L29,3.781V8.219z" />
        <path d="M16,11c-1.654,0-3,1.346-3,3c0,1.654,1.346,3,3,3c0.353,0,0.686-0.072,1-0.184V18l0,0c0,0,0,0,0,0c0,0.551-0.449,1-1,1   s-1-0.448-1-1c0-0.552-0.448-1-1-1s-1,0.448-1,1c0,1.654,1.346,3,3,3s3-1.346,3-3c0,0,0,0,0,0l0,0v-4C19,12.346,17.654,11,16,11z    M16,15c-0.551,0-1-0.449-1-1s0.449-1,1-1s1,0.448,1,1S16.551,15,16,15z" />
        <path d="M30,29h-8c-0.552,0-1,0.448-1,1s0.448,1,1,1h8c0.552,0,1-0.448,1-1S30.552,29,30,29z" />
        <path d="M19,29h-2c-0.552,0-1,0.448-1,1s0.448,1,1,1h2c0.552,0,1-0.448,1-1S19.552,29,19,29z" />
        <path d="M14,29H2c-0.552,0-1,0.448-1,1s0.448,1,1,1h12c0.552,0,1-0.448,1-1S14.552,29,14,29z" />
      </g>
      <g id="substitution" />
      <g id="medal_award" />
      <g id="strategy" />
      <g id="card" />
      <g id="gloves" />
      <g id="stadium" />
      <g id="keeper" />
      <g id="time" />
      <g id="horns" />
      <g id="flag" />
      <g id="referee" />
      <g id="player" />
      <g id="injury" />
      <g id="supporter" />
      <g id="coach" />
      <g id="cone" />
      <g id="captain" />
      <g id="match" />
      <g id="score" />
      <g id="celender" />
      <g id="grass" />
      <g id="game" />
      <g id="subsitutions" />
      <g id="bench" />
    </svg>
  </SvgIcon>
);

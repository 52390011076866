import { AppBar, IconButton, Toolbar, Typography } from "@mui/material";
import { useHeader } from "../../providers/HeaderProvider";
import { Menu } from "@mui/icons-material";
import { FC } from "react";

type HeaderProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  isPublicPage?: boolean;
};

export const Header: FC<HeaderProps> = ({ open, setOpen, isPublicPage }) => {
  const { title } = useHeader();
  return (
    <AppBar
      position="fixed"
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        borderBottom: "1px solid",
        borderColor: (t) => t.palette.divider,
      }}
      elevation={0}
    >
      <Toolbar>
        {isPublicPage ? null : (
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={() => setOpen(!open)}
          >
            <Menu />
          </IconButton>
        )}
        <Typography variant="h5" component="div" sx={{ flexGrow: 1 }}>
          {title}
        </Typography>
      </Toolbar>
    </AppBar>
  );
};

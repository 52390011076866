import {
  Avatar,
  Box,
  Button,
  ButtonProps,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import TextField from "../TextField/TextField";
import { useTranslation } from "react-i18next";
import { FC } from "react";
import playerValidationSchema from "./playerValidationSchema";
import { Player } from "../../types";
import SelectField from "../SelectField/SelectField";
import { PLAYER_POSITION, PlayerPosition } from "../../consts";
import PlayerIcon from "../PlayerIcon/PlayerIcon";
import SwitchField from "../SwitchPlayer/SwitchPlayer";
import { Delete } from "@mui/icons-material";

type PlayerEditorProps = {
  edit?: boolean;
  onSubmit: (values: Partial<Player>) => void;
  // onDelete: () => void;
  initialValues: Partial<Player>;
  loading?: boolean;
  disabled?: boolean;
  onDelete?: ButtonProps["onClick"];
};

const PlayerEditor: FC<PlayerEditorProps> = ({
  edit = false,
  onSubmit,
  onDelete,
  // onDelete,
  initialValues,
  loading,
  disabled,
}) => {
  const { t } = useTranslation();

  return (
    <Formik
      enableReinitialize
      validationSchema={playerValidationSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, isValid, dirty, values }) => {
        console.log(isValid, values);
        return (
          <>
            <DialogTitle
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Avatar
                  sx={{
                    bgcolor: "primary.light",
                    width: 28,
                    height: 28,
                    fontSize: 20,
                    mr: 1,
                  }}
                >
                  <PlayerIcon
                    fontSize="inherit"
                    position={initialValues.position || PLAYER_POSITION.DEFAULT}
                  />
                </Avatar>
                {values.name || (edit ? t("EDIT_PLAYER") : t("ADD_PLAYER"))}
              </Box>
              {edit && (
                <IconButton color="error" onClick={onDelete}>
                  <Delete />
                </IconButton>
              )}
            </DialogTitle>
            <DialogContent
              style={{
                width: 400,
                overflow: "hidden",
                maxWidth: "100%",
                padding: "8px 24px 24px 24px",
              }}
            >
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Field
                      // error={getFieldError(errors, touched, 'allowance')}
                      component={TextField}
                      name="name"
                      disabled={loading || disabled}
                      label={t("PLAYER_NAME")}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      // error={getFieldError(errors, touched, 'allowance')}
                      component={SelectField}
                      name="position"
                      disabled={loading || disabled}
                      label={t("PLAYER_POSITION")}
                      options={Object.keys(PLAYER_POSITION).map((key) => ({
                        label: t(
                          PLAYER_POSITION[
                            key as PlayerPosition
                          ] as unknown as string
                        ),
                        value: key,
                      }))}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      // error={getFieldError(errors, touched, 'allowance')}
                      component={TextField}
                      name="rank"
                      disabled={loading || disabled}
                      label={t("PLAYER_RANK")}
                      type="number"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      // error={getFieldError(errors, touched, 'allowance')}
                      component={TextField}
                      name="entries"
                      disabled={loading || disabled}
                      label={t("PLAYER_ENTRIES")}
                      type="number"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      // error={getFieldError(errors, touched, 'allowance')}
                      component={SwitchField}
                      name="temporary"
                      disabled={loading || disabled}
                      label={t("PLAYER_TEMPORARY")}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      fullWidth
                      variant="contained"
                      type="submit"
                      color="inherit"
                      onClick={() => handleSubmit()}
                      disabled={!dirty || !isValid || loading || disabled}
                    >
                      {t(edit ? "EDIT_PLAYER" : "ADD_PLAYER")}
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            </DialogContent>
          </>
        );
      }}
    </Formik>
  );
};

export default PlayerEditor;

import * as React from "react";
import Box from "@mui/material/Box";
import Backdrop from "@mui/material/Backdrop";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";

type SpeedyAction = {
  icon: React.ReactNode;
  name: string;
  onClick: () => void;
  hide?: boolean;
};

type SpeedyProps = {
  actions: SpeedyAction[];
};

export default function Speedy({ actions }: SpeedyProps) {
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);

  const visibleActions = actions.filter((action) => !action.hide);

  return (
    <Box
      sx={{
        position: "fixed",
        bottom: (t) => t.spacing(3),
        right: (t) => t.spacing(3),
        zIndex: (t) => t.zIndex.appBar + 1,
      }}
    >
      <Backdrop sx={{ bgcolor: "rgba(0, 0, 0, .9)" }} open={open} />
      <SpeedDial
        ariaLabel="SpeedDial tooltip example"
        icon={<SpeedDialIcon />}
        onClick={() => setOpen(!open)}
        open={open}
        color="#000"
      >
        {visibleActions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            tooltipOpen
            tooltipPlacement="left"
            onClick={() => {
              action.onClick();
              handleClose();
            }}
          />
        ))}
      </SpeedDial>
    </Box>
  );
}

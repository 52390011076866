import { type DocumentData, QuerySnapshot } from "firebase/firestore";

type CollectionToArray<T> = {
  collection: QuerySnapshot<DocumentData, DocumentData> | undefined;
  idPrefix?: string;
  filter?: (t: T) => boolean;
};

const collectionToArray = <T>({
  collection,
  idPrefix = "document",
  filter = () => true,
}: CollectionToArray<T>) => {
  if (!collection) return [];

  return collection.docs
    .map((doc: DocumentData) => {
      const docData = doc.data();
      return {
        ...docData,
        [`${idPrefix}Id`]: doc.id,
      };
    })
    .filter(filter) as T[];
};

export default collectionToArray;

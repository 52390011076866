import {
  DirectionsRun,
  Person,
  SettingsAccessibility,
  SportsSoccer,
} from "@mui/icons-material";
import { PLAYER_POSITION, PlayerPosition } from "../../consts";
import { FC } from "react";
import { IconProps } from "@mui/material";
const MAP_PLAYER_POSITION_TO_ICON = {
  [PLAYER_POSITION.GOALIE]: SportsSoccer,
  [PLAYER_POSITION.DEFENSE]: SettingsAccessibility,
  [PLAYER_POSITION.DEFAULT]: Person,
  [PLAYER_POSITION.OFFENSE]: DirectionsRun,
};

type PlayerIconProps = {
  position: PlayerPosition;
} & IconProps;

const PlayerIcon: FC<PlayerIconProps> = ({ position, ...rest }) => {
  if (!position) return null;

  const Icon = MAP_PLAYER_POSITION_TO_ICON[position];

  // @ts-ignore
  return <Icon {...rest} />;
};

export default PlayerIcon;

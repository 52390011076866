export const PLAYER_POSITION = {
  DEFENSE: "DEFENSE",
  OFFENSE: "OFFENSE",
  GOALIE: "GOALIE",
  DEFAULT: "DEFAULT",
} as const;

export type PlayerPosition =
  (typeof PLAYER_POSITION)[keyof typeof PLAYER_POSITION];

export const NUMBER_OF_PLAYERS_PER_TEAM = 7;
export const NUMBER_OF_TEAMS_PER_DRAFT = 3;

export const MATCH_STATUSES = {
  SCHEDULED: "scheduled",
  COMPLETED: "completed",
  CANCELED: "canceled",
} as const;

import { FC, ReactNode, useContext, useState } from "react";
import { createContext } from "react";
import { Box } from "@mui/material";
import SideNavigation from "../components/SideNavigation/SideNavigation";
import { Header } from "../components/Header/Header";

type HeaderContextType = {
  title: string;
  setTitle: (title: string) => void;
  headerRef: HTMLDivElement | null;
  setHeaderRef: (ref: HTMLDivElement | null) => void;
};

const HeaderContext = createContext<HeaderContextType>({
  title: "matchmaker",
  setTitle: () => {},
  headerRef: null,
  setHeaderRef: () => {},
});

export const useHeader = () => {
  return useContext(HeaderContext);
};

const normalizeDocumentTitle = (title: string) => {
  return title ? title + " | matchmaker" : "matchmaker";
};

export const HeaderProvider: FC<{
  children: ReactNode;
  isPublic?: boolean;
}> = ({ children, isPublic = false }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [title, setTitle] = useState<string>("");
  const [headerRef, setHeaderRef] = useState<HTMLDivElement | null>(null);

  const setTitleActual = (title: string) => {
    setTitle(title);
    document.title = normalizeDocumentTitle(title);
  };

  return (
    <HeaderContext.Provider
      value={{ title, setTitle: setTitleActual, headerRef, setHeaderRef }}
    >
      <Box sx={{ display: "flex", flexDirection: "row", height: "100%" }}>
        <Header isPublicPage={isPublic} open={open} setOpen={setOpen} />
        <SideNavigation isPublicPage={isPublic} open={open} setOpen={setOpen} />
        <Box sx={{ flex: 1, overflow: "hidden" }}>{children}</Box>
      </Box>
    </HeaderContext.Provider>
  );
};

import { FC } from "react";
import { TextField as MuiTextField } from "@mui/material";

type TextFieldProps = {
  field: any;
  form: any;
  helperText?: string;
  error?: string;
};

const TextField: FC<TextFieldProps> = ({
  field,
  form: _form,
  helperText,
  error,
  ...rest
}) => {
  return (
    <MuiTextField
      helperText={error || helperText}
      fullWidth
      size="small"
      variant="outlined"
      error={Boolean(error)}
      {...field}
      {...rest}
    />
  );
};

export default TextField;

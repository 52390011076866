import { FC, useMemo, useCallback } from "react";
import Box from "@mui/material/Box";
import {
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  SwipeableDrawer as MuiDrawer,
  Theme,
  CSSObject,
  styled,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import {
  Logout,
  Settings,
  Person,
  Home,
  ConfirmationNumber,
  AddCircle,
  SportsSoccer,
} from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useUser } from "../../providers/UserProvider";

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(8)} + 1px)`,
  [theme.breakpoints.down("lg")]: {
    width: 0,
  },
});

const Drawer = styled(MuiDrawer)(({ theme, open, variant }) => {
  if (variant === "permanent") {
    return {
      flexShrink: 0,
      whiteSpace: "nowrap",
      boxSizing: "border-box",
      width: drawerWidth,
      ...(open && {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": openedMixin(theme),
      }),
      ...(!open && {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme),
      }),
    };
  }
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

type SideNavigationProps = {
  isPublicPage?: boolean;
  open: boolean;
  setOpen: (open: boolean) => void;
};

type SideNavigationList = {
  name: string;
  title?: string;
  divider?: boolean;
  items: {
    icon: JSX.Element;
    name: string;
    path?: string;
    disabled?: boolean;
    onClick?: () => void;
  }[];
};

const SideNavigation: FC<SideNavigationProps> = ({
  isPublicPage,
  open,
  setOpen,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { signout } = useUser();
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("lg"));

  const navigateAndClose = useCallback(
    (to: string) => {
      navigate(to);
      setOpen(false);
    },
    [navigate, setOpen]
  );

  const navigationList = useMemo<SideNavigationList>(
    () => ({
      name: "navigation-list",
      items: [
        {
          icon: <Home color="inherit" />,
          name: t("HOME"),
          path: "/",
        },
        {
          icon: <Person color="inherit" />,
          name: t("MANAGE_PLAYERS"),
          path: "/players",
        },
        {
          icon: <SportsSoccer color="inherit" />,
          name: t("MANAGE_GAMES"),
          path: "/matches",
        },
        {
          icon: <AddCircle color="inherit" />,
          name: t("CREATE_MATCH"),
          path: "/match/create",
        },
        {
          icon: <ConfirmationNumber color="inherit" />,
          name: t("MANAGER_REPORT"),
          path: "/manager/report",
        },
      ],
    }),
    [t]
  );

  const systemList = useMemo<SideNavigationList>(
    () => ({
      name: "system-list",
      title: t("SYSTEM"),
      divider: true,
      items: [
        {
          icon: <Settings color="inherit" />,
          name: t("SETTINGS"),
          path: "/settings",
        },
        {
          icon: <Logout color="inherit" />,
          name: t("LOGOUT"),
          onClick: signout,
        },
      ],
    }),
    [signout, t]
  );

  const lists = useMemo(
    () => [navigationList, systemList],
    [navigationList, systemList]
  );

  if (isPublicPage) {
    return null;
  }

  return (
    <Drawer
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      PaperProps={{
        sx: {
          backgroundImage: "none",
          borderRight: "1px solid",
          borderColor: (t) => t.palette.divider,
        },
      }}
      swipeAreaWidth={25}
      variant={matches ? "temporary" : "permanent"}
    >
      <Box
        sx={{
          width: 250,
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
        role="presentation"
      >
        <DrawerHeader />
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            flex: 1,
          }}
        >
          {lists.map((list) => (
            <Box key={list.name}>
              {list.divider && <Divider sx={{ width: "100%" }} />}

              <List
                disablePadding
                sx={{ width: "100%", maxWidth: 360 }}
                component="nav"
                key={list.name}
                subheader={
                  list?.title && open ? (
                    <ListSubheader
                      sx={{
                        backgroundColor: "transparent",
                      }}
                    >
                      {list.title}
                    </ListSubheader>
                  ) : undefined
                }
              >
                {list.items.map((item) => {
                  const isSelected = location.pathname === item.path;

                  return (
                    <ListItem
                      key={`item-${item.name}`}
                      disablePadding
                      sx={{ display: "block" }}
                    >
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? "initial" : "center",
                          px: 2.5,

                          ...(isSelected && {
                            color: (t) => t.palette.primary.light,
                          }),
                        }}
                        disabled={item.disabled}
                        selected={isSelected}
                        onClick={
                          item.onClick
                            ? item.onClick
                            : () => item.path && navigateAndClose(item.path)
                        }
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                            color: isSelected ? "primary.main" : "inherit",
                          }}
                        >
                          {item.icon}
                        </ListItemIcon>
                        <ListItemText
                          primary={item.name}
                          sx={{ opacity: open ? 1 : 0 }}
                        />
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </List>
            </Box>
          ))}
        </Box>
      </Box>
    </Drawer>
  );
};

export default SideNavigation;

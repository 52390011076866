import React, { FC, createContext, useEffect, useState } from "react";
import { Alert, Box } from "@mui/material";

type NotificationsContextProps = {
  notification: Notification | null;
  setNotification: (notification: Notification | null) => void;
};

const NotificationsContext = createContext<NotificationsContextProps>({
  notification: null,
  setNotification: () => {},
});

type NotificationsProviderProps = {
  children: React.ReactNode;
};

type Notification = {
  title: string;
  body: string;
};

export const useNotification = () => {
  const { notification, setNotification } =
    React.useContext(NotificationsContext);

  return { notification, setNotification };
};

const NotificationsProvider: FC<NotificationsProviderProps> = ({
  children,
}) => {
  const [notification, setNotification] = useState<Notification | null>(null);

  useEffect(() => {
    let t: any;

    if (notification) {
      t = setTimeout(() => {
        setNotification(null);
      }, 5000);
    }

    return () => {
      clearTimeout(t);
    };
  }, [notification, setNotification]);

  return (
    <NotificationsContext.Provider value={{ notification, setNotification }}>
      <Box position="fixed" right={0} top={64} zIndex={99999999} padding={2}>
        {notification && (
          <Alert variant="filled" onClose={() => setNotification(null)}>
            {notification?.title}: {notification?.body}
          </Alert>
        )}
      </Box>
      {children}
    </NotificationsContext.Provider>
  );
};

export default NotificationsProvider;

import React, { useEffect, useMemo } from "react";
import { usePlayers } from "../../providers/PlayersProvider";
import { useTranslation } from "react-i18next";
import Page from "../../components/Page/Page";
import { Box, Container, Paper, Skeleton, Typography } from "@mui/material";
import { useHeader } from "../../providers/HeaderProvider";
import { useManagerSettings } from "../../providers/ManagerSettingsProvider";
import CustomCardHeader from "../../components/CustomCard/CustomCardHeader";
import { Savings } from "@mui/icons-material";
import formatNumber from "../../utils/formatNumber";
import { EntriesChip } from "../../components/Chips/EntriesChip";

const PlayersLimited = () => {
  const { t } = useTranslation();
  const { players, loading } = usePlayers();
  const { setTitle } = useHeader();

  const { managerSettings, loading: loadingManagerSettings } =
    useManagerSettings();

  const permanentPlayers = useMemo(
    () =>
      (loading
        ? []
        : players?.filter((player) => !player.temporary) || []
      ).sort((a, b) => a.name.localeCompare(b.name, "he")),
    [players, loading]
  );

  useEffect(() => {
    setTitle(t("MATCHMAKER"));
  }, [t, setTitle]);

  const budget = useMemo(() => managerSettings?.budget || 0, [managerSettings]);

  return (
    <Page>
      <Container
        maxWidth="md"
        sx={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          height: "100%",
          p: 2,
        }}
      >
        <Paper sx={{ width: "100%", height: "100%" }}>
          <CustomCardHeader
            title={t("MANAGER_REPORT")}
            secondaryText={
              <Box display="flex">
                <Savings
                  color={
                    budget ? (budget > 0 ? "success" : "error") : undefined
                  }
                  style={{ marginLeft: 4 }}
                />
                <Typography
                  variant="subtitle1"
                  color={
                    budget ? (budget > 0 ? "success.main" : "error") : undefined
                  }
                  fontWeight={800}
                  dir="ltr"
                >
                  {!loadingManagerSettings ? (
                    formatNumber(budget)
                  ) : (
                    <Skeleton width={80} />
                  )}{" "}
                </Typography>
              </Box>
            }
          />
          <Box sx={{ height: "calc(100% - 64px)", overflowY: "auto" }}>
            {permanentPlayers.map((player) => (
              <Box
                sx={{
                  display: "flex",
                  height: 48,
                  px: 2,
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: (t) => `1px solid ${t.palette.divider}`,
                  transition: (t) =>
                    t.transitions.create("background-color", {
                      duration: t.transitions.duration.shortest,
                    }),
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor: "grey.800",
                  },
                  "&:last-child": {
                    borderBottom: "none",
                  },
                }}
              >
                <Typography>{player.name}</Typography>
                <EntriesChip entries={player.entries} />
              </Box>
            ))}
          </Box>
        </Paper>
      </Container>
    </Page>
  );
};

export default PlayersLimited;

import React, { FC, useEffect, useMemo } from "react";
import "../locale";
import { matchPath, Outlet, useLocation, useNavigate } from "react-router-dom";
import { HeaderProvider } from "../providers/HeaderProvider";
import RTL from "./RTL/RTL";
import { useUser } from "../providers/UserProvider";
import SplashScreen from "./SplashScreen/SplashScreen";
import PlayersProvider from "../providers/PlayersProvider";
import ManagerSettingsProvider from "../providers/ManagerSettingsProvider";
import NotificationsProvider from "../providers/NotificationsProvider";

const publicRoutes = ["/match/view/:id", "/manager/report"];

const matchPublicRoutes = (currentRoute: string) => {
  if (publicRoutes.some((route) => matchPath(route, currentRoute))) {
    return true;
  }
};

const Layout: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user, loading } = useUser();

  const isInPublicRoute = useMemo(
    () => !user && matchPublicRoutes(location.pathname),
    [user, location]
  );

  useEffect(() => {
    if (isInPublicRoute) {
      return;
    }

    if (!user && !loading) {
      navigate("/login");
    } else if (location.pathname === "/login" && user) {
      navigate("/");
    }
  }, [isInPublicRoute, user, loading, navigate, location]);

  if (loading) {
    return <SplashScreen />;
  }

  if (isInPublicRoute) {
    return (
      <RTL>
        <ManagerSettingsProvider>
          <PlayersProvider>
            <HeaderProvider isPublic>
              <Outlet />
            </HeaderProvider>
          </PlayersProvider>
        </ManagerSettingsProvider>
      </RTL>
    );
  }

  if (!user) {
    return (
      <RTL>
        <Outlet />
      </RTL>
    );
  }

  return (
    <RTL>
      <NotificationsProvider>
        <ManagerSettingsProvider>
          <PlayersProvider>
            <HeaderProvider>
              <Outlet />
            </HeaderProvider>
          </PlayersProvider>
        </ManagerSettingsProvider>
      </NotificationsProvider>
    </RTL>
  );
};

export default Layout;

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Dialog } from "@mui/material";
import Paper from "../../components/Paper/Paper";
import { useUser } from "../../providers/UserProvider";
import { addPlayer } from "../../api/players";
import PlayerEditor from "../../components/PlayerEditor/PlayerEditor";
import { Player } from "../../types";

function AddPlayer() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const { user } = useUser();

  return (
    <Dialog
      open
      PaperProps={{
        loading,
      }}
      // eslint-disable-next-line
      // @ts-ignore
      PaperComponent={Paper}
      onClose={() => navigate("/players")}
    >
      <PlayerEditor
        initialValues={{}}
        loading={loading}
        onSubmit={async (values) => {
          setLoading(true);
          try {
            await addPlayer({ ...values, userId: user?.uid } as Player);
          } catch (error) {
            console.log(error);
          } finally {
            setLoading(false);
            navigate("/players");
          }
        }}
      />
    </Dialog>
  );
}

export default AddPlayer;

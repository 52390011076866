import * as React from "react";
import { Add, Share } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import Speedy from "../../components/Speedy/Speedy";

type PlayersActionsProps = {
  onAddPlayer: () => void;
  onShare: () => void;
};

export default function PlayersActions({
  onAddPlayer,
  onShare,
}: PlayersActionsProps) {
  const { t } = useTranslation();

  const actions = [
    { icon: <Add />, name: t("ADD_PLAYER"), onClick: onAddPlayer },
    { icon: <Share />, name: t("SHARE_ENTRIES"), onClick: onShare },
  ];

  return <Speedy actions={actions} />;
}

import { Box, Typography, useTheme } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import LogoLoading from "./LogoLoading";

const SplashScreen: FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        width: "100%",
        backgroundColor: theme.palette.background.paper,
        position: "fixed",
        left: 0,
        top: 0,
        flexDirection: "column",
      }}
    >
      <LogoLoading />
      <Typography fontWeight={600} variant="subtitle1" sx={{ marginTop: 2 }}>
        {t("LOADING_APP")}
      </Typography>
    </Box>
  );
};

export default SplashScreen;

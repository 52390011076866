import {
  NUMBER_OF_PLAYERS_PER_TEAM,
  NUMBER_OF_TEAMS_PER_DRAFT,
  PLAYER_POSITION,
} from "../consts";
import { Player } from "../types";
import calculateTeamAverageScore from "./calculateTeamAverageScore";

type TempTeam = {
  players: Player[];
  averageRank: number;
  hasGoalie?: boolean;
};
type TempTeams = TempTeam[];

type Options = {
  numberOfTeams: number;
  numberOfPlayersPerTeam: number;
};

type Return = {
  [key: string]: TempTeam;
};

const shufflePlayers = (array: Player[]) =>
  array.sort(() => Math.random() - 0.5).sort((a, b) => a.rank - b.rank);

// this function will sort players by their rank and
const distributePlayers = ({
  numberOfPlayersPerTeam,
  numberOfTeams,
  players,
  teams,
}: {
  numberOfPlayersPerTeam: number;
  numberOfTeams: number;
  players: Player[];
  teams: TempTeams;
}) => {
  if (numberOfPlayersPerTeam === 1 && numberOfTeams === 1) {
    teams[0].players.push(players.shift()!);
    return;
  }

  // since players are sorted by rank ascending, please assign one player at time to each team to ensure that each team will have a player with the lowest rank
  for (let i = 0; i < numberOfPlayersPerTeam; i++) {
    for (let j = 0; j < numberOfTeams; j++) {
      if (teams[j].players.length < numberOfPlayersPerTeam) {
        teams[j].players.push(players.shift()!);
      }
    }
  }
};

export const createDraft = (
  activePlayers: Player[],
  options: Options | undefined = {
    numberOfTeams: NUMBER_OF_TEAMS_PER_DRAFT,
    numberOfPlayersPerTeam: NUMBER_OF_PLAYERS_PER_TEAM,
  }
): Return => {
  const { numberOfTeams, numberOfPlayersPerTeam } = options;

  if (activePlayers.length < numberOfTeams * numberOfPlayersPerTeam) {
    throw new Error("Not enough players to form the required teams.");
  }

  // Separate goalies and non-goalies
  const goalies = activePlayers.filter(
    (player) => player.position === PLAYER_POSITION.GOALIE
  );
  const nonGoalies = activePlayers.filter(
    (player) => player.position !== PLAYER_POSITION.GOALIE
  );

  // Shuffle arrays for randomness
  shufflePlayers(goalies);
  shufflePlayers(nonGoalies);

  const teams: TempTeams = Array.from({ length: numberOfTeams }, () => ({
    players: [],
    averageRank: 0,
  }));

  // Distribute goalies to each team if possible
  for (let i = 0; i < numberOfTeams; i++) {
    if (goalies.length > 0) {
      teams[i].players.push(goalies.pop()!);
      teams[i].hasGoalie = true;
    }
  }

  distributePlayers({
    numberOfPlayersPerTeam,
    numberOfTeams,
    players: nonGoalies,
    teams,
  });

  // Calculate average rank for each team, if team has goalie, ignore his rank from the calculation
  teams.forEach((team) => {
    team.averageRank = calculateTeamAverageScore(team.players);
  });

  let shouldRebalance = true;
  let maxRebalanceTries = 100;
  let rebalanceTries = 0;
  let rebalanceThreshold = 0.5;

  while (shouldRebalance && rebalanceTries < maxRebalanceTries) {
    const averageRanks = teams.map((team) => team.averageRank);
    const maxRank = Math.max(...averageRanks);
    const minRank = Math.min(...averageRanks);

    const maxTeam = teams.find((team) => team.averageRank === maxRank)!;
    const minTeam = teams.find((team) => team.averageRank === minRank)!;

    const maxPlayer = maxTeam.players.find(
      (player) => player.position !== PLAYER_POSITION.GOALIE
    )!;

    const minPlayer = minTeam.players.find(
      (player) => player.position !== PLAYER_POSITION.GOALIE
    )!;

    maxTeam.players = maxTeam.players.filter((player) => player !== maxPlayer);
    minTeam.players = minTeam.players.filter((player) => player !== minPlayer);
    maxTeam.players.push(minPlayer);
    minTeam.players.push(maxPlayer);

    maxTeam.averageRank = calculateTeamAverageScore(maxTeam.players);
    minTeam.averageRank = calculateTeamAverageScore(minTeam.players);

    const averageRanksAfterRebalance = teams.map((team) => team.averageRank);
    const maxRankAfterRebalance = Math.max(...averageRanksAfterRebalance);
    const minRankAfterRebalance = Math.min(...averageRanksAfterRebalance);
    const diff = maxRankAfterRebalance - minRankAfterRebalance;

    if (diff < rebalanceThreshold) {
      shouldRebalance = false;
    } else {
      rebalanceTries++;
    }
  }

  return Object.fromEntries(
    teams.map((team, index) => [`team-${index + 1}`, team])
  );
};

export const createSortableDraft = (draft: Return) => {
  return Object.fromEntries(
    Object.entries(draft).map(([key, value]) => [
      key,
      value.players.map((player) => player.documentId),
    ])
  );
};

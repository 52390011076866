import { Box, Typography, TypographyProps } from "@mui/material";
import { FC, ReactNode } from "react";

type CustomCardHeaderProps = {
  borderColor?: string;
  icon?: ReactNode;
  secondaryText?: ReactNode;
  title: string;
  titleVariant?: TypographyProps["variant"];
  border?: boolean;
};

const CustomCardHeader: FC<CustomCardHeaderProps> = ({
  borderColor,
  title,
  icon,
  secondaryText,
  titleVariant = "h5",
  border = true,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        // borderTop: "2px solid",
        // borderTopColor: borderColor
        //   ? borderColor
        //   : (t) => t.palette.primary.dark,
        alignItems: "center",
        borderBottom: border ? "1px solid" : "none",
        borderBottomColor: (t) => t.palette.divider,
        height: 64,
        flexShrink: 0,
        padding: "0 16px",
        borderRadius: 0,
      }}
    >
      {icon}
      <Typography
        variant={titleVariant}
        component="div"
        fontWeight={500}
        flex={1}
        noWrap
      >
        {title}
      </Typography>
      {secondaryText}
    </Box>
  );
};

export default CustomCardHeader;

import { object, date, string, number } from "yup";

export const matchSettingsValidationSchema = object().shape({
  settings: object().shape({
    date: date().required("REQUIRED"),
    time: date().required("REQUIRED"),
    location: string().required("REQUIRED"),
    numberOfPlayersPerTeam: number().required("REQUIRED"),
    numberOfTeams: number().required("REQUIRED"),
  }),
});

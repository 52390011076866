import { Savings } from "@mui/icons-material";
import {
  Box,
  ButtonBase,
  Paper,
  Skeleton,
  Typography,
  TypographyProps,
} from "@mui/material";
import { FC, ReactNode } from "react";

type IconAndTextProps = {
  icon: typeof Savings;
  text?: ReactNode;
  borderColor?: string;
  loading?: boolean;
  onClick?: () => void;
  dir?: "ltr" | "rtl";
  textColor?: TypographyProps["color"];
};

const IconAndText: FC<IconAndTextProps> = ({
  icon: Icon,
  text,
  textColor,
  borderColor,
  loading,
  onClick,
  dir,
}) => (
  <ButtonBase
    disabled={!onClick}
    sx={{ width: "100%", height: "100%" }}
    onClick={onClick}
    dir={dir}
  >
    <Paper
      sx={{
        padding: 2,
        width: "100%",
        display: "flex",
        borderTop: "2px solid",
        borderColor: borderColor || "transparent",
        alignItems: "center",
        fontSize: (t) => t.typography.h6,
        flexDirection: "column",
        justifyContent: "center",
        textAlign: "center",
        height: "100%",
      }}
    >
      <Box sx={{ mb: 1 }}>
        <Icon sx={{ color: borderColor }} fontSize="large" />
      </Box>
      {loading && <Skeleton width={"50%"} />}
      {!loading && (
        <Typography color={textColor} variant="h6">
          {text}
        </Typography>
      )}
    </Paper>
  </ButtonBase>
);

export default IconAndText;

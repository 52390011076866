import React, { FC } from "react";
import {
  Box,
  ListItem,
  ListItemButton,
  Paper,
  Typography,
} from "@mui/material";
import { Player } from "../../types";
import { Rating } from "../Rating/Rating";
import { EntriesChip } from "../Chips/EntriesChip";
import { mapPositionToColor, PositionChip } from "../Chips/PositionChip";

export type PlayerListItemProps = Player & {
  showDivider?: boolean;
  onClick?: () => void;
  active?: boolean;
  isActive?: boolean;
  isOverlay?: boolean;
  compact?: boolean;
  showEntries?: boolean;
  showRank?: boolean;
};

const PlayerListItem: FC<PlayerListItemProps> = ({
  documentId,
  rank,
  name,
  position,
  showDivider,
  onClick,
  isOverlay,
  isActive,
  compact,
  showEntries,
  showRank = true,
  entries,
}) => {
  return (
    <ListItem
      key={`item-${documentId}`}
      divider={showDivider}
      onClick={onClick}
      disablePadding
      sx={{
        borderLeft: compact
          ? "none"
          : `4px solid ${mapPositionToColor[position]}`,
        mb: compact ? 0 : 1,
        height: 52,
        ...(isActive && {
          bgcolor: "#121212",
          opacity: 0.8,
        }),
        ...(isOverlay && {
          cursor: "grabbing",
          bgcolor: "#121212",
          boxShadow: (theme) => theme.shadows[2],
        }),
      }}
    >
      <Paper
        square
        style={{ width: "100%", height: "100%", overflow: "hidden" }}
      >
        <ListItemButton sx={{ alignItems: "center", height: "100%" }}>
          <Box display="flex" flex={1} alignItems="center">
            {!showEntries && <PositionChip position={position} />}
            <Box flex={1}>
              <Typography noWrap variant="subtitle1" fontWeight={500}>
                {name}
              </Typography>
            </Box>
            {showEntries && (
              <Box flexShrink={0}>
                <EntriesChip entries={entries} />
              </Box>
            )}
            {showRank && (
              <Box flexShrink={0} ml={1}>
                <Rating rank={rank} compact={true} />
              </Box>
            )}
          </Box>
        </ListItemButton>
      </Paper>
    </ListItem>
  );
};

export default PlayerListItem;

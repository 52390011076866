import { Star } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import React, { FC } from "react";

// should return a number with two decimal after the dot.
const getRankWithTwoDecimalsAndAddZerosIfNoDecimals = (rank: number = 0) => {
  if (typeof rank !== "number") {
    return "0.00";
  }

  const rankString = rank.toString();
  const hasDecimal = rankString.includes(".");
  if (!hasDecimal) {
    return `${rank}.00`;
  }
  const [, decimal] = rankString.split(".");
  if (decimal.length === 1) {
    return `${rank}0`;
  }
  return rank;
};

type RatingProps = {
  rank: number;
  compact?: boolean;
};

export const Rating: FC<RatingProps> = ({ rank }) => (
  <Box display="flex" alignItems="center">
    <Star sx={{ mr: 1 }} color="warning" />
    <Typography variant="subtitle1" fontWeight={800} width={35}>
      {getRankWithTwoDecimalsAndAddZerosIfNoDecimals(rank)}
    </Typography>
  </Box>
);

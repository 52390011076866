import {
  FC,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { Helmet } from "react-helmet";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { createBotitoTheme } from "../theme";

const SYSTEM_SETTINGS_LOCAL_STORAGE_KEY = "botito.systemSettings";
const DEFAULT_SYSTEM_SETTINGS = {
  darkMode: true,
};

export type SystemSettings = {
  darkMode: boolean;
};

export type SystemSettingsContextType = {
  systemSettings: SystemSettings;
  updateSystemSettings: (value: Partial<SystemSettings>) => void;
};

const SystemSettingsContext = createContext<SystemSettingsContextType>({
  systemSettings: {
    darkMode: true,
  },
  updateSystemSettings: () => {},
});

export const useSystemSettings = () => {
  return useContext(SystemSettingsContext);
};

const loadSystemSettingsFromLocalStorage = (): SystemSettings => {
  const systemSettingsFromLocalStorate = localStorage.getItem(
    SYSTEM_SETTINGS_LOCAL_STORAGE_KEY
  );

  if (!systemSettingsFromLocalStorate) {
    localStorage.setItem(
      SYSTEM_SETTINGS_LOCAL_STORAGE_KEY,
      JSON.stringify(DEFAULT_SYSTEM_SETTINGS)
    );

    return DEFAULT_SYSTEM_SETTINGS;
  } else {
    return JSON.parse(systemSettingsFromLocalStorate);
  }
};

const updateSystemSettingsInLocalStorage = (value: SystemSettings) => {
  localStorage.setItem(
    SYSTEM_SETTINGS_LOCAL_STORAGE_KEY,
    JSON.stringify(value)
  );
};

const SystemSettingsProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [systemSettings, setSystemSettings] = useState<SystemSettings>(
    loadSystemSettingsFromLocalStorage()
  );

  const updateSystemSettings = useCallback(
    (nextSystemSettings: Partial<SystemSettings>) => {
      const mergedSystemSettings = {
        ...systemSettings,
        ...nextSystemSettings,
      };

      setSystemSettings(mergedSystemSettings);
      updateSystemSettingsInLocalStorage(mergedSystemSettings);
    },
    [systemSettings]
  );

  const theme = useMemo(
    () => createBotitoTheme(systemSettings.darkMode ? "dark" : "light"),
    [systemSettings.darkMode]
  );

  const themeColor = useMemo(
    () =>
      systemSettings.darkMode
        ? theme.palette.background.default
        : theme.palette.primary.dark,
    [systemSettings.darkMode, theme.palette]
  );

  return (
    <SystemSettingsContext.Provider
      value={{ systemSettings, updateSystemSettings }}
    >
      <ThemeProvider theme={theme}>
        <Helmet>
          <meta name="theme-color" content={themeColor} />
        </Helmet>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </SystemSettingsContext.Provider>
  );
};

export default SystemSettingsProvider;

import * as React from "react";
import Speedy from "../../components/Speedy/Speedy";

export type Action = {
  icon: React.ReactNode;
  name: string;
  onClick: () => void;
  hide?: boolean;
  disabled?: boolean;
} & Record<string, any>;

export default function MatchActions({ actions }: { actions: Action[] }) {
  return <Speedy actions={actions} />;
}

import { TFunction } from "i18next";

export const getEntriesText = (entries: number, t: TFunction) => {
  if (!entries) return t("PLAYER_NO_ENTRIES");
  if (entries > 0) {
    return t("PLAYER_ENTRIES_LEFT", {
      entries: entries || 0,
    });
  } else if (entries < 0) {
    return t("PLAYER_MINUS_ENTRIES", {
      entries: Math.abs(entries),
    });
  }
};

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import he from "./he";
import en from "./en";

const resources = {
  he: {
    translation: he,
  },
  en: {
    translation: en,
  },
};

// eslint-disable-next-line import/no-named-as-default-member
i18n.use(initReactI18next).init({
  resources,
  lng: "he",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;

import React, { FC, ReactNode } from "react";
import { Box, useTheme } from "@mui/material";
type PageProps = {
  children?: ReactNode;
};

const Page: FC<PageProps> = ({ children }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        mt: 8,
        height: "calc(100% - 64px)",
        [theme.breakpoints.down("md")]: {
          mt: 7,
          height: "calc(100% - 56px)",
        },
      }}
    >
      {children}
    </Box>
  );
};

export default Page;

const he = {
  AUTHENTICATE: "התחברות",
  EMAIL: 'דוא"ל',
  PASSWORD: "סיסמה",
  SEARCH_PLAYER: "חיפוש שחקן",
  SEARCH_PLAYER_HELPER: "נמצאו {{count}} שחקנים",
  NEXT: "הבא",
  BACK: "חזור",

  // POSITIONS
  OFFENSE: "התקפה",
  DEFENSE: "הגנה",
  GOALIE: "שוער",
  DEFAULT: "כללי",

  // SIDE NAV
  MANAGE_GAMES: "ניהול משחקים",
  MANAGE_PLAYERS: "ניהול שחקנים",
  SYSTEM: "מערכת",
  SETTINGS: "הגדרות",
  LOGOUT: "התנתקות",

  MATCH_STATUS: "סטטוס המשחק",
  MATCH_DETAILS: "פרטי המשחק",

  //FABS
  ADD_PLAYER: "הוסף שחקן",
  EDIT_PLAYER: "ערוך שחקן",
  DELETE_PLAYER: "מחק שחקן",

  // PLAYER
  PLAYER_NAME: "שם שחקן",
  PLAYER_POSITION: "תפקיד",
  PLAYER_RANK: "דירוג",
  PLAYER_ENTRIES: "כניסות",
  PLAYERS: "שחקנים",

  N_PLAYERS_UNASSIGNED: "{{n}} שחקנים לא משוייכים",
  N_OF_MATCHES: "{{count}} משחקים",
  DRAG_PLAYERS_HERE: "גרור שחקנים לכאן",

  // MATCHES
  CREATE_MATCH: "יצירת משחק",
  SELECT_PLAYERS: "בחר שחקנים",
  GENERATE_PLAYLIST: "צור קבוצות",
  TEAM_TITLE: "קבוצה #{{index}}",
  POWERS: "כוחות",
  DRAG_AND_DROP: "ניתן לגרור ולשחרר שחקנים בין הקבוצות",
  QUICK_SHUFFLE: "ערבוב כוחות",
  SELECT_PLAYERS_DECSRIPTION: "בחר את השחקנים שישתתפו ביום המשחק",
  CREATE_MATCH_DAY: "צור יום משחק",

  HOME: "דף הבית",

  LOADING_APP: "מרים לוולה...",

  TODAY: "היום",

  COPIED_TO_CLIPBOARD: "לינק לשיתוף הועתק",

  DATA_COPIED: "המידע הועתק בהצלחה",
  PLAYERS_DATA_COPIED_TO_CLIPBOARD: "דוח כניסות",

  PLAYER_TEMPORARY: "שחקן זמני",
  SHARE_ENTRIES: "שתף כניסות",

  N_OF_PLAYERS: "{{count}} שחקנים",

  PLAYER_NAME_AND_ENTRIES: "{{name}}: *{{entries}}* כניסות",
  ENTRIES_DEBT: "חובות",
  MANAGER_REPORT: "דוח מנהל",

  STATUS_SCHEDULED: "המשחק מתוזמן",
  STATUS_COMPLETED: "המשחק נגמר",

  // HOME
  WELCOME_BACK: "טוב שחזרת, {{name}}",
  WELCOME_NO_MATCHES: "אין משחקים מתוזמנים כרגע",

  MATCHMAKER: "מאצ'מייקר",

  MATCH_SETTINGS: "הגדרות המשחק",
  MATCH_SETTINGS_DESCRIPTION: "כאן ניתן לקבוע זמן, מיקום והגדרות נוספות למשחק",

  SELECT_DATE: "בחר תאריך",
  SELECT_TIME: "בחר שעה",
  MATCH_DATE: "תאריך המשחק",
  MATCH_TIME: "שעת המשחק",
  NUMBER_OF_TEAMS: "מספר קבוצות",
  NUMBER_OF_PLAYERS_PER_TEAM: "מספר שחקנים בקבוצה",
  MATCH_LOCATION: "מיקום המשחק",
  REVIEW_AND_PUBLISH: "בדוק ושמור",

  SAVE: "שמור",
  MATCH_SAVED: "המשחק נשמר בהצלחה",
  MATCH_SAVED_DESCRIPTION:
    "המשחק נשמר בהצלחה. ניתן למצוא את המשחק בדף הניהול שלך",

  NUMBER_OF_PLAYERS: "מספר שחקנים",
  EDIT_MATCH: "ערוך משחק",
  TOTAL_NUMBER_OF_PLAYERS: "סה״כ שחקנים",
  MATCH: "משחק",

  WELCOME_MATCHES: "יש {{numberOfMatches}} משחקים מתוזמנים עבורך",
  NEXT_MATCH: "המשחק הבא",
  MATCH_HISTORY: "היסטוריית משחקים",
  MORE_MATCHES: "משחקים נוספים",

  REQUIRED: "שדה חובה",
  MIN_PLAYERS: "בחרת מעט מדיי שחקנים למשחק",
  MAX_PLAYERS: "בחרת יותר מדיי שחקנים למשחק",

  EDIT_MATCH_DAY: "עריכת משחק",

  SHARE: "שתף",
  DELETE: "מחק",
  EDIT: "ערוך",
  COMPLETE_MATCH: "סיים משחק",

  COMPLETE_MATCH_TITLE: "האם ברצונך לסיים את המשחק?",
  COMPLETE_MATCH_DESCRIPTION:
    "סיום המשחק יעודכן במערכת ומספר הכרטיסיות יעודכן לשחקנים שהשתתפו",

  DELETE_MATCH_TITLE: "האם אתה בטוח שברצונך למחוק את המשחק ב{{location}}?",
  DELETE_MATCH_DESCRIPTION: "המשחק ימחק לצמיתות ולא יהיה ניתן לשחזר אותו",

  CONFIRM: "אישור",
  CANCEL: "ביטול",

  MATCH_DAY: "יום משחק",

  DELETE_PLAYER_CONFIRMATION:
    "האם אתה בטוח שברצונך למחוק את השחקן? פעולה זו אינה ניתנת לביטול",

  DARK_MODE: "מצב כהה",
  DARK_MODE_HELPER_TEXT: "הפעל או השבת מצב כהה",

  EDIT_BUDGET: "ערוך תקציב",
  EDIT_BUDGET_HELPER_TEXT: "כאן ניתן להזין את התקציב שיש בקופת הקבוצה",

  EDIT_MANAGER_SETTINGS_BUDGET: "ערוך תקציב",
  MANAGER_BUDGET: "תקציב",
  MANAGER_BUDGET_HELPER_TEXT: "הזן את התקציב שלך בקופת הקבוצה",

  TEAMS: "קבוצות",

  PLAYER_NO_ENTRIES: "אין כניסות",
  PLAYER_ENTRIES_LEFT: "{{entries}} כניסות",
  PLAYER_MINUS_ENTRIES: "{{entries}}- כניסות",

  CHANGELOG: "שינויים ועדכונים",
  LATEST_VERSION: "גרסה אחרונה: v{{v}}",

  GAME_CANCELED: "המשחק בוטל",
  GAME_HISTORY: "היסטוריית משחקים",
  SCHEDULED_GAMES: "משחקים עתידיים",
  NO_SCHEDULED_GAMES: "לא נמצאו משחקים עתידיים",
  NO_GAMES_HISTORY: "לא נמצאו משחקים בהיסטוריה",

  TROUBLE_MAKERS: "שחקנים עם חובות",
  YOU_CAN_CHILL_FOR_NOW: "שחקנים בסדר",
  COMMON_ACTIONS: "פעולות נפוצות",
  SEARCH: "חיפוש",

  ASSIGN_PLAYERS: "שייך שחקנים",
  ACTIONS: "פעולות",
  AVERAGE_RANK: "דירוג ממוצע",

  RESET_TEAMS: "איפוס קבוצות",
};

export default he;

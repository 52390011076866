import React from "react";
import Router from "./components/Router";
import UserProvider from "./providers/UserProvider";

function App() {
  return (
    <UserProvider>
      <Router />
    </UserProvider>
  );
}

export default App;

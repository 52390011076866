import {
  AccessTime,
  Delete,
  Done,
  Edit,
  EventAvailable,
  Groups,
  LocationOn,
  PendingOutlined,
  Person,
  Share,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Chip,
  Container,
  Divider,
  Grid,
  Hidden,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { doc } from "firebase/firestore";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useDocument } from "react-firebase-hooks/firestore";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { db } from "../../hooks/firebase";
import { Match, Player } from "../../types";
import formatDate, { FORMATS } from "../../utils/formatDate";
import { useHeader } from "../../providers/HeaderProvider";
import { usePlayers } from "../../providers/PlayersProvider";
import MatchCompactLoader from "../Matches/MatchCopactLoader";
import MatchActions, { Action } from "./MatchActions";
import { completeMatch, deleteMatch } from "../../api/matches";
import Confirmation from "../../components/Confirmation/Confirmation";
import IconAndText from "../../components/IconAndText/IconAndText";
import { useNotification } from "../../providers/NotificationsProvider";
import Page from "../../components/Page/Page";
import { Panel } from "../../components/Panel/Panel";
import PlayersList from "../../components/PlayersList/PlayersList";
import { mapChipColorToVariant } from "../Matches/MatchCompact";
import { Team } from "../../components/Team/Team";

const MatchPage: FC<{ isPublicPage?: boolean }> = ({
  isPublicPage = false,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const { setNotification } = useNotification();
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [isConfirmationLoading, setConfirmationLoading] = useState(false);
  const [completeMatchConfirmation, setCompleteMatchConfirmation] =
    useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { setTitle } = useHeader();
  const { players } = usePlayers();

  const { id } = useParams();
  const [document, loadingMatch] = useDocument(doc(db, "matches", id || ""));

  const empty = !document;
  const shouldGenData = !empty && !loadingMatch;

  const match = useMemo<Match | undefined>(() => {
    if (shouldGenData) {
      return {
        documentId: document.id,
        ...document.data(),
      } as Match;
    }

    return undefined;
  }, [document, shouldGenData]);

  const copyShareURLToClipboard = useCallback(() => {
    const shareableURL = window.location.host + "/match/view/" + id;
    navigator.clipboard.writeText(shareableURL);

    setNotification({
      title: t("COPIED_TO_CLIPBOARD"),
      body: shareableURL,
    });
  }, [id, setNotification, t]);

  useEffect(() => {
    setTitle(t("MATCH_DAY"));
  }, [setTitle, t]);

  const matchActions = useMemo<Action[]>(
    () => [
      {
        icon: <Edit />,
        name: t("EDIT"),
        onClick: () => navigate("/match/edit/" + id),
      },
      {
        icon: <Share />,
        name: t("SHARE"),
        onClick: copyShareURLToClipboard,
      },
      {
        color: "success",
        disabled: match?.status === "completed",
        dividerAbove: true,
        icon: <Done />,
        name: t("COMPLETE_MATCH"),
        onClick: () => setCompleteMatchConfirmation(true),
      },
      {
        color: "error",
        dividerAbove: true,
        icon: <Delete />,
        name: t("DELETE"),
        onClick: () => setConfirmationOpen(true),
        variant: "outlined",
      },
    ],
    [
      navigate,
      copyShareURLToClipboard,
      t,
      id,
      setConfirmationOpen,
      setCompleteMatchConfirmation,
      match,
    ]
  );

  const matchSettings = useMemo(
    () =>
      !match
        ? []
        : [
            {
              label: t("MATCH_STATUS"),
              value: (
                <Chip
                  color={mapChipColorToVariant[match?.status]}
                  label={t("STATUS_" + match?.status?.toUpperCase())}
                />
              ),
              icon: match?.status === "completed" ? Done : PendingOutlined,
            },
            {
              label: t("MATCH_LOCATION"),
              value: match?.settings?.location,
              icon: LocationOn,
            },
            {
              label: t("MATCH_DATE"),
              value: formatDate(new Date(match?.settings?.date), FORMATS.SHORT),
              icon: EventAvailable,
            },
            {
              label: t("MATCH_TIME"),
              value: formatDate(new Date(match?.settings?.time), "HH:mm"),
              icon: AccessTime,
            },
            {
              label: t("TEAMS"),
              value: match?.settings?.numberOfTeams + " " + t("TEAMS"),
              icon: Groups,
            },
            {
              icon: Person,
              label: t("PLAYERS"),
              value:
                match?.settings?.numberOfPlayersPerTeam *
                  match?.settings?.numberOfTeams +
                " " +
                t("PLAYERS"),
            },
          ],
    [t, match]
  );

  if (!id) {
    return null;
  }

  if (loadingMatch) {
    return (
      <Page>
        <Container
          sx={{
            mt: 2,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <MatchCompactLoader />
            </Grid>
          </Grid>
        </Container>
      </Page>
    );
  }

  if (!match?.documentId) {
    return null;
  }

  return (
    <Page>
      <Box display="flex" height="100%" width="100%" overflow="auto" flex={1}>
        <Hidden lgDown>
          <Panel hideHeader permanent>
            <Grid container spacing={2} sx={{ p: 2 }}>
              <Grid item xs={12}>
                <Typography variant="h6">{t("MATCH_DETAILS")}</Typography>
              </Grid>
              {matchSettings.map((setting) => (
                <Grid item xs={12} key={setting.label}>
                  <Box>
                    <Typography variant="subtitle1" fontWeight={700}>
                      {setting.label}
                    </Typography>
                    <Typography variant="body1">{setting.value}</Typography>
                  </Box>
                </Grid>
              ))}
              {!isPublicPage && (
                <>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h6">{t("COMMON_ACTIONS")}</Typography>
                  </Grid>
                  {matchActions.map((action) => (
                    <>
                      {action.dividerAbove && (
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                      )}
                      <Grid item xs={12} key={action.name}>
                        <Button
                          color={action.color || "inherit"}
                          variant={action.variant || "contained"}
                          fullWidth
                          startIcon={action.icon}
                          onClick={action.onClick}
                          disabled={action.disabled}
                        >
                          {action.name}
                        </Button>
                      </Grid>
                    </>
                  ))}
                </>
              )}
            </Grid>
          </Panel>
        </Hidden>
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
          }}
        >
          <Hidden lgUp>
            <Grid container spacing={2} sx={{ p: 2 }}>
              {matchSettings.map((setting) => (
                <Grid item xs={12} key={setting.label}>
                  <IconAndText text={setting.value} icon={setting.icon} />
                </Grid>
              ))}
            </Grid>
          </Hidden>
          {match.teams &&
            Object.keys(match.teams)
              .sort((a, b) => {
                const idA = a.split("team-")[1];
                const idB = b.split("team-")[1];

                return parseInt(idA) - parseInt(idB);
              })
              .map((teamKey, _index) => {
                const teamPlayers: Player[] = match.teams[teamKey].map(
                  (playerId) =>
                    players?.find((player) => player.documentId === playerId)!
                );

                return (
                  <Team
                    key={teamKey}
                    containerId={teamKey}
                    players={teamPlayers}
                  >
                    <PlayersList
                      showEntries
                      showRank
                      players={teamPlayers}
                      onPlayerClick={(player) =>
                        navigate(`/players/edit/${player.documentId}`)
                      }
                    />
                  </Team>
                );
              })}
        </Box>
      </Box>
      {!isPublicPage && (
        <Hidden lgUp>
          <MatchActions actions={matchActions} />
        </Hidden>
      )}
      <Confirmation
        loading={isConfirmationLoading}
        open={confirmationOpen}
        onClose={() => setConfirmationOpen(false)}
        onConfirm={async () => {
          setConfirmationLoading(true);

          try {
            await deleteMatch(id);

            navigate("/matches");
          } catch (error) {
            console.error(error);
          }

          setConfirmationLoading(false);
        }}
        title={t("DELETE_MATCH_TITLE", {
          location: match?.settings?.location,
        })}
        description={t("DELETE_MATCH_DESCRIPTION")}
      />
      <Confirmation
        loading={isConfirmationLoading}
        open={completeMatchConfirmation}
        onClose={() => setCompleteMatchConfirmation(false)}
        onConfirm={async () => {
          setConfirmationLoading(true);
          try {
            const playerIds = Object.values(match?.teams || {}).flat();
            await completeMatch(id, playerIds);
          } catch (error) {
            console.error(error);
          }

          setCompleteMatchConfirmation(false);
          setConfirmationLoading(false);
        }}
        title={t("COMPLETE_MATCH_TITLE", {
          location: match?.settings?.location,
        })}
        description={t("COMPLETE_MATCH_DESCRIPTION")}
      />
      <Confirmation
        loading={isConfirmationLoading}
        open={confirmationOpen}
        onClose={() => setConfirmationOpen(false)}
        onConfirm={async () => {
          setConfirmationLoading(true);

          try {
            await deleteMatch(id);

            navigate("/matches");
          } catch (error) {
            console.error(error);
          }

          setConfirmationLoading(false);
        }}
        title={t("DELETE_MATCH_TITLE", {
          location: match?.settings?.location,
        })}
        description={t("DELETE_MATCH_DESCRIPTION")}
      />
    </Page>
  );
};

export default MatchPage;

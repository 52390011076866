export const CURRENCIES = {
  ILS: "ils",
} as const;

export const mapCurrencyToSymbol = {
  [CURRENCIES.ILS]: "₪",
} as const;

function formatNumber(
  number: number,
  currency = CURRENCIES.ILS
): number | string {
  const isNegative = number < 0;
  let n: number | string = number;

  if (typeof number === "string") {
    n = parseFloat(number);
  }

  if ((n !== 0 && !n) || typeof n !== "number") return n;

  n = Math.abs(Math.round(n * 100) / 100);
  n = isNegative ? -n : n;
  n = n.toFixed(2);

  const currencySymbol = mapCurrencyToSymbol[currency];
  return `${currencySymbol}${n
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
}

export default formatNumber;

import { Shuffle } from "@mui/icons-material";
import { Box, Fab, useTheme } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";

type QuickShuffleProps = {
  onClick: () => void;
};

const QuickShuffle: FC<QuickShuffleProps> = ({ onClick }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Box
      sx={{
        position: "fixed",
        bottom: (t) => t.spacing(8),
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: (t) => t.zIndex.appBar + 1,
        right: 0,
        width: "100%",
        [theme.breakpoints.up("md")]: {
          bottom: (t) => t.spacing(7.5),
        },
      }}
    >
      <Fab variant="extended" size="small" color="primary" onClick={onClick}>
        <Shuffle sx={{ mr: 1 }} />
        {t("QUICK_SHUFFLE")}
      </Fab>
    </Box>
  );
};

export default QuickShuffle;

import { Container, Grid, Typography } from "@mui/material";
import { Field, useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { Form } from "react-router-dom";
import DatePicker from "../../../DatePicker/DatePicker";
import TimePicker from "../../../TimePicker/TimePicker";
import TextField from "../../../TextField/TextField";
import getFieldError from "../../../../utils/getFieldError";
import { Info } from "@mui/icons-material";
import CustomCard from "../../../CustomCard/CustomCard";

const MatchSettings = () => {
  const { errors, touched } = useFormikContext();
  const { t } = useTranslation();

  return (
    <Container
      maxWidth="md"
      sx={{ display: "flex", alignItems: "center", p: 2 }}
    >
      <CustomCard
        title={t("MATCH_SETTINGS")}
        headerBorder={false}
        height="100%"
      >
        <Form style={{ padding: 16, flex: 1 }}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sx={{
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Info sx={{ mr: 1 }} fontSize="inherit" />
              <Typography variant="body1">
                {t("MATCH_SETTINGS_DESCRIPTION")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Field
                error={getFieldError(errors, touched, "settings.date", t)}
                component={DatePicker}
                name="settings.date"
                label={t("MATCH_DATE")}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                error={getFieldError(errors, touched, "settings.time", t)}
                component={TimePicker}
                name="settings.time"
                label={t("MATCH_TIME")}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                error={getFieldError(errors, touched, "settings.location", t)}
                component={TextField}
                name="settings.location"
                label={t("MATCH_LOCATION")}
                type="text"
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                error={getFieldError(
                  errors,
                  touched,
                  "settings.numberOfTeams",
                  t
                )}
                component={TextField}
                name="settings.numberOfTeams"
                label={t("NUMBER_OF_TEAMS")}
                type="number"
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                error={getFieldError(
                  errors,
                  touched,
                  "settings.numberOfPlayersPerTeam",
                  t
                )}
                component={TextField}
                name="settings.numberOfPlayersPerTeam"
                label={t("NUMBER_OF_PLAYERS_PER_TEAM")}
                type="number"
              />
            </Grid>
          </Grid>
        </Form>
      </CustomCard>
    </Container>
  );
};

export default MatchSettings;

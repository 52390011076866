import {
  Navigate,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import Layout from "./Layout";
import Login from "../routes/Login/Login";
import PrivateRoute from "./PrivateRoute/PrivateRoute";
import Players from "../routes/Players/Players";
import EditPlayer from "../routes/EditPlayer/EditPlayer";
import AddPlayer from "../routes/AddPlayer/AddPlayer";
import CreateMatch from "../routes/CreateMatch/CreateMatch";
import Home from "../routes/Home/Home";
import MatchPage from "../routes/MatchPage/MatchPage";
import Matches from "../routes/Matches/Matches";
import EditMatch from "../routes/EditMatch/EditMatch";
import Settings from "../routes/Settings/Settings";
import EditManagerSettings from "../routes/EditManagerSettings/EditManagerSettings";
import PlayersLimited from "../routes/PlayersLimited/PlayersLimited";

const Router = () => {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route element={<Layout />}>
          <Route path="/login" element={<Login />} />
          <Route
            path="/"
            element={
              <PrivateRoute>
                <Home />
              </PrivateRoute>
            }
          />
          <Route
            path="players"
            element={
              <PrivateRoute>
                <Players />
              </PrivateRoute>
            }
          >
            <Route path="add" element={<AddPlayer />} />
            <Route path="edit/:id" element={<EditPlayer />} />
          </Route>
          <Route
            path="matches"
            element={
              <PrivateRoute>
                <Matches />
              </PrivateRoute>
            }
          />
          <Route path="match">
            <Route
              path="create"
              element={
                <PrivateRoute>
                  <CreateMatch />
                </PrivateRoute>
              }
            />
            <Route
              path="edit/:id"
              element={
                <PrivateRoute>
                  <EditMatch />
                </PrivateRoute>
              }
            />
            <Route path="view/:id" element={<MatchPage isPublicPage />} />
            <Route
              path=":id"
              element={
                <PrivateRoute>
                  <MatchPage />
                </PrivateRoute>
              }
            />
          </Route>
          <Route
            path="settings"
            element={
              <PrivateRoute>
                <Settings />
              </PrivateRoute>
            }
          >
            <Route path="edit/:display" element={<EditManagerSettings />} />
          </Route>
          <Route
            path="entries/report"
            element={<Navigate to="/manager/report" replace />}
          />
          <Route path="manager/report" element={<PlayersLimited />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Route>
      </>
    )
  );

  return <RouterProvider router={router} />;
};

export default Router;

import { useState } from "react";
import { useNavigate } from "react-router-dom";
// import { Paper, TextField } from 'components';
import { Formik, Form, Field } from "formik";
import { useTranslation } from "react-i18next";
import loginValidationSchema from "./loginValidationSchema";
import Paper from "../../components/Paper/Paper";
import {
  Alert,
  Button,
  Grid,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import TextField from "../../components/TextField/TextField";
import { useUser } from "../../providers/UserProvider";

const LoginContainer = styled("div")(({ theme }) => ({
  width: "100%",
  height: "100vh",
  maxHeight: "100vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(4),
}));

function Login() {
  const { t } = useTranslation();
  const auth = useUser();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();
  const [success, setSuccess] = useState(false);
  const theme = useTheme();

  return (
    <LoginContainer>
      <Formik
        validationSchema={loginValidationSchema}
        initialValues={{
          email: "",
          password: "",
        }}
        onSubmit={async (values) => {
          setLoading(true);
          try {
            const res = await auth.signin(values.email, values.password);
            setLoading(false);

            if (!res?.success) {
              setError(res?.message);
            } else {
              setError(undefined);
              setSuccess(true);
              navigate("/");
            }
          } catch (error) {
            setLoading(false);
            setError("error");
          }
        }}
      >
        {({ handleSubmit }) => (
          <Form>
            <Paper
              loading={loading}
              sx={{
                position: "relative",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                padding: theme.spacing(2),
                maxWidth: 600,
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h6">{t("AUTHENTICATE")}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Field
                    // FIXME
                    // error={getFieldError(errors, touched, 'email')}
                    component={TextField}
                    name="email"
                    label={t("EMAIL")}
                    fullWidth
                    size="small"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    // error={getFieldError(errors, touched, 'password')}
                    type="password"
                    component={TextField}
                    name="password"
                    label={t("PASSWORD")}
                    fullWidth
                    size="small"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    disabled={loading || success}
                    onClick={() => handleSubmit()}
                    variant="contained"
                    type="submit"
                    fullWidth
                  >
                    {t("LOGIN")}
                  </Button>
                </Grid>
                {error && (
                  <Grid item xs={12}>
                    <Alert variant="outlined" severity="error">
                      {error}
                    </Alert>
                  </Grid>
                )}
              </Grid>
            </Paper>
          </Form>
        )}
      </Formik>
    </LoginContainer>
  );
}

export default Login;

import { object, array } from "yup";
import { MatchSettings } from "../../../../types";

export const selectPlayersValidationSchema = object().shape({
  selectedPlayers: array().when("settings", (settings) => {
    const s = settings[0] as unknown as MatchSettings;
    return array()
      .required("REQUIRED")
      .min(s.numberOfPlayersPerTeam! * s.numberOfTeams!, "MIN_PLAYERS")
      .max(s.numberOfPlayersPerTeam! * s.numberOfTeams!, "MAX_PLAYERS");
  }),
});

import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Player } from "../../types";
import { useTranslation } from "react-i18next";
import { Avatar, Box, Skeleton, Typography } from "@mui/material";
import { Rating } from "../Rating/Rating";
import { EntriesChip } from "../Chips/EntriesChip";
import { PositionChip } from "../Chips/PositionChip";

type PlayersTableProps = {
  players?: Player[];
  loading?: boolean;
  onPlayerClick?: (player: Player) => void;
};

const TableHeader = ({ label }: { label: string }) => (
  <TableCell>
    <Typography variant="subtitle1">{label}</Typography>
  </TableCell>
);

export function PlayersTable({
  players,
  loading,
  onPlayerClick,
}: PlayersTableProps) {
  const { t } = useTranslation();

  return (
    <TableContainer sx={{ height: "100%" }}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableHeader label={t("PLAYER_NAME")} />
            <TableHeader label={t("PLAYER_POSITION")} />
            <TableHeader label={t("PLAYER_RANK")} />
            <TableHeader label={t("PLAYER_ENTRIES")} />
          </TableRow>
        </TableHead>
        {loading && (
          <TableBody>
            {new Array(10).fill(0).map((_, index) => (
              <TableRow key={`loader-${index}`}>
                <TableCell>
                  <Skeleton />
                </TableCell>
                <TableCell>
                  <Skeleton />
                </TableCell>
                <TableCell>
                  <Skeleton />
                </TableCell>
                <TableCell>
                  <Skeleton />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        )}
        <TableBody>
          {players?.map((player) => (
            <TableRow
              hover
              onClick={() => onPlayerClick?.(player)}
              key={player.documentId}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                borderColor: (t) => t.palette.divider,
                cursor: "pointer",
              }}
            >
              <TableCell component="th" scope="row">
                <Box display="flex" alignItems="center">
                  <Avatar sx={{ width: 32, height: 32, mr: 1 }} />
                  <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                    {player.name}
                  </Typography>
                </Box>
              </TableCell>
              <TableCell>
                <PositionChip position={player.position} />
              </TableCell>
              <TableCell>
                <Rating compact rank={player.rank} />
              </TableCell>
              <TableCell>
                <EntriesChip entries={player.entries} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

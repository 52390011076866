import { Box, Chip, Typography } from "@mui/material";
import { Match, MatchStatus } from "../../types";
import { FC } from "react";
import {
  CancelOutlined,
  CheckCircleOutline,
  Groups,
  Person,
  ScheduleOutlined,
  SportsScore,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import CustomCard from "../../components/CustomCard/CustomCard";
import { MATCH_STATUSES } from "../../consts";
import formatDate, { FORMATS } from "../../utils/formatDate";
import { ClockIcon } from "@mui/x-date-pickers/icons";

type MatchCompactProps = Match["settings"] & {
  id: string;
  variant?: MatchStatus;
};

const MatchSetting = ({ value, icon: Icon }: { value: any; icon: any }) => {
  return (
    <Box
      sx={{
        display: "flex",
        height: "100%",
        flex: 1,
        textAlign: "center",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Icon sx={{ mr: 1 }} />
      <Typography variant="subtitle1">{value}</Typography>
    </Box>
  );
};

const mapColorToVariant = {
  [MATCH_STATUSES.SCHEDULED]: "success.dark",
  [MATCH_STATUSES.COMPLETED]: "grey.500",
  [MATCH_STATUSES.CANCELED]: "warning.main",
};

export const mapChipColorToVariant = {
  [MATCH_STATUSES.SCHEDULED]: "success",
  [MATCH_STATUSES.COMPLETED]: "default",
  [MATCH_STATUSES.CANCELED]: "warning",
} as Record<MatchStatus, "success" | "default" | "warning">;

export const mapIconToVariant = {
  [MATCH_STATUSES.SCHEDULED]: <ScheduleOutlined />,
  [MATCH_STATUSES.COMPLETED]: <CheckCircleOutline />,
  [MATCH_STATUSES.CANCELED]: <CancelOutlined />,
};

const MatchCompact: FC<MatchCompactProps> = ({
  id,
  date,
  time,
  location,
  numberOfTeams,
  numberOfPlayersPerTeam,
  variant = MATCH_STATUSES.SCHEDULED,
}) => {
  const navigate = useNavigate();

  return (
    <CustomCard
      icon={<SportsScore fontSize="large" sx={{ mr: 0.5 }} />}
      title={location}
      titleVariant="h6"
      secondaryText={
        <Chip
          sx={{ fontWeight: 700 }}
          icon={mapIconToVariant[variant]}
          label={formatDate(new Date(date), FORMATS.SHORT)}
          color={mapChipColorToVariant[variant]}
        />
      }
      borderColor={mapColorToVariant[variant]}
      button
      onClick={() => navigate("/match/" + id)}
      divider={false}
      headerBorder={variant === MATCH_STATUSES.SCHEDULED}
    >
      {variant === MATCH_STATUSES.SCHEDULED && (
        <Box p={2} sx={{ display: "flex" }}>
          <MatchSetting
            icon={ClockIcon}
            value={formatDate(new Date(time), "HH:ii")}
          />
          <MatchSetting icon={Groups} value={numberOfTeams} />
          <MatchSetting
            icon={Person}
            value={`${numberOfPlayersPerTeam * numberOfTeams}`}
          />
        </Box>
      )}
    </CustomCard>
  );
};

export default MatchCompact;

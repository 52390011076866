import { PaletteMode, Theme, createTheme } from "@mui/material";
import { purple } from "@mui/material/colors";

export const createBotitoTheme = (mode: PaletteMode): Theme =>
  createTheme({
    direction: "rtl",
    typography: {
      fontFamily: "Chakra Petch, Heebo",
    },
    palette: {
      mode,
      primary: purple,
    },
  });

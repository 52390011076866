import {
  Autocomplete,
  Box,
  Checkbox,
  Container,
  Grid,
  Paper,
  TextField,
} from "@mui/material";
import { FC, useMemo } from "react";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

import { Player, SelectedPlayers } from "../../../../types";
import { useTranslation } from "react-i18next";
import PlayersList from "../../../PlayersList/PlayersList";
import { useFormikContext } from "formik";
import getFieldError from "../../../../utils/getFieldError";
import CustomCardHeader from "../../../CustomCard/CustomCardHeader";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

type SelectPlayersProps = {
  players: Player[] | undefined;
  loading?: boolean;
  selectedPlayers?: SelectedPlayers;
  onSelectPlayers: (players: SelectedPlayers) => void;
};

const SelectPlayers: FC<SelectPlayersProps> = ({
  selectedPlayers,
  onSelectPlayers,
  players,
  loading,
}) => {
  const { errors, touched } = useFormikContext();
  const { t } = useTranslation();
  const playerOptions = useMemo(
    () =>
      loading
        ? []
        : players
            ?.map((player) => ({
              label: player.name,
              value: player.documentId,
            }))
            .sort((a, b) => {
              return a.label.localeCompare(b.label, "he");
            }),
    [players, loading]
  );

  const actualSelectedPlayers = useMemo(
    () =>
      loading
        ? []
        : selectedPlayers
            ?.map(
              (selectedPlayer) =>
                players?.find(
                  (player) => player.documentId === selectedPlayer.value
                )!
            )
            .sort((a, b) => {
              return a.name.localeCompare(b.name, "he");
            }),
    [selectedPlayers, players, loading]
  );

  const error = useMemo(
    () => getFieldError(errors, touched, "selectedPlayers", t, true),
    [errors, touched, t]
  );

  return (
    <Container
      maxWidth="md"
      sx={{
        display: "flex",
        alignItems: "center",
        height: "100%",
        p: 2,
      }}
    >
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          flex: 1,
          overflow: "hidden",
        }}
      >
        <CustomCardHeader title={t("SELECT_PLAYERS")} />
        <Box display="flex" flexDirection="column" height="100%" width="100%">
          <Box p={2}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Autocomplete
                  limitTags={2}
                  groupBy={(option) => option.label[0]}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  loading={loading}
                  value={loading ? undefined : selectedPlayers}
                  onChange={(_event, newValue) => {
                    onSelectPlayers(newValue);
                  }}
                  fullWidth
                  options={playerOptions!}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.label}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("PLAYERS")}
                      error={Boolean(error)}
                      helperText={error}
                      placeholder={t("PLAYERS")}
                    />
                  )}
                  multiple
                />
              </Grid>
            </Grid>
          </Box>
          <Box
            sx={{
              height: "calc(100% - 152px)",
              overflowY: "auto",
            }}
          >
            <PlayersList
              compact
              players={actualSelectedPlayers}
              loading={loading}
            />
          </Box>
        </Box>
      </Paper>
    </Container>
  );
};

export default SelectPlayers;

import { MenuItem, TextField } from "@mui/material";
import React, { ChangeEvent, FC } from "react";

type SelectFieldProps = {
  field: any;
  form: any;
  helperText?: string;
  error?: boolean;
  options: { label: string; value: string }[];
};

const SelectField: FC<SelectFieldProps> = ({
  field,
  form,
  options,
  helperText,
  ...rest
}) => {
  const onChange = (e: ChangeEvent<{ value: string }>) => {
    form.setFieldValue(field.name, e.target.value);
  };

  return (
    <TextField
      value={field.value}
      onChange={onChange}
      {...rest}
      fullWidth
      size="small"
      variant="outlined"
      select
      helperText={rest.error || helperText}
    >
      {options.map((option, key) => (
        <MenuItem key={key} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default SelectField;

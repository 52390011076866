import React, { useMemo } from "react";
import { Box, Container, Grid, Skeleton, Typography } from "@mui/material";
import { useUser } from "../../providers/UserProvider";
import { useTranslation } from "react-i18next";
import { Savings } from "@mui/icons-material";
import { useHeader } from "../../providers/HeaderProvider";
import { useNavigate } from "react-router-dom";
import { useMatchesByStatus } from "../../hooks/useMatches";
import MatchCompact from "../Matches/MatchCompact";
import { useManagerSettings } from "../../providers/ManagerSettingsProvider";
import formatNumber from "../../utils/formatNumber";
import MatchCompactLoader from "../Matches/MatchCopactLoader";
import CustomCard from "../../components/CustomCard/CustomCard";
import IconAndText from "../../components/IconAndText/IconAndText";
import ProblemMakers from "./ProblemMakers";
import Page from "../../components/Page/Page";

const Home = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user } = useUser();
  const { setTitle } = useHeader();
  const { managerSettings, loading: loadingManagerSettings } =
    useManagerSettings();
  const { matches: scheduledMatches, loading: loadingScheduledMatches } =
    useMatchesByStatus("scheduled");

  const loading = loadingScheduledMatches;

  React.useEffect(() => {
    setTitle(t("MATCHMAKER"));
  }, [t, setTitle]);

  const nearestMatch = useMemo(() => {
    return scheduledMatches?.sort((a, b) => {
      return a.settings.date < b.settings.date ? 1 : -1;
    })[0];
  }, [scheduledMatches]);

  const budget = useMemo(() => managerSettings?.budget || 0, [managerSettings]);

  return (
    <Page>
      <Container maxWidth="xl" sx={{ py: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <CustomCard
              divider={false}
              title={t("WELCOME_BACK", { name: user?.displayName || "ירין" })}
            >
              <Box sx={{ p: 2 }}>
                {loading && <Skeleton variant="text" />}
                {scheduledMatches?.length > 0 && (
                  <Typography variant="body1">
                    {t("WELCOME_MATCHES", {
                      numberOfMatches: scheduledMatches.length,
                    })}
                  </Typography>
                )}
                {!loading && scheduledMatches?.length === 0 && (
                  <Typography variant="body1">
                    {t("WELCOME_NO_MATCHES")}
                  </Typography>
                )}
              </Box>
            </CustomCard>
          </Grid>
          <Grid item xs={4}>
            <IconAndText
              dir="ltr"
              loading={loadingManagerSettings}
              icon={Savings}
              text={formatNumber(budget)}
              textColor={
                budget ? (budget > 0 ? "success.main" : "error") : undefined
              }
              onClick={() => navigate("/settings/edit/budget")}
              borderColor={
                budget
                  ? budget > 0
                    ? "success.main"
                    : "error.main"
                  : undefined
              }
            />
          </Grid>
          <Grid item xs={12}>
            <ProblemMakers />
          </Grid>
          {loading && (
            <>
              <Grid item xs={12}>
                <Typography variant="h5">
                  <Skeleton width="50%" />{" "}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <MatchCompactLoader />
              </Grid>
            </>
          )}
          {nearestMatch && (
            <>
              <Grid item xs={12}>
                <Typography variant="h5">{t("NEXT_MATCH")}</Typography>
              </Grid>
              <Grid item xs={12}>
                <MatchCompact
                  id={nearestMatch.documentId}
                  {...nearestMatch.settings}
                />
              </Grid>
            </>
          )}
        </Grid>
      </Container>
    </Page>
  );
};

export default Home;

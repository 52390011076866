import { Close } from "@mui/icons-material";
import { Box, IconButton, SxProps, Typography } from "@mui/material";
import { FC } from "react";

type PanelProps = {
  open?: boolean;
  onClose?: () => void;
  permanent?: boolean;
  children?: React.ReactNode;
  width?: number;
  sx?: SxProps;
  title?: React.ReactNode;
  hideHeader?: boolean;
};

export const Panel: FC<PanelProps> = ({
  open,
  onClose,
  title,
  permanent,
  children,
  width = 350,
  sx,
  hideHeader,
}) => (
  <Box
    sx={{
      width: permanent ? width : open ? width : 0,
      flexShrink: 0,
      height: "100%",
      borderRight: "1px solid",
      borderColor: (t) => t.palette.divider,
      transition: (t) => t.transitions.create(["width"]),
      display: "flex",
      flexDirection: "column",
      overflow: !open ? "hidden" : undefined,
      ...sx,
    }}
  >
    {!hideHeader && (
      <Box
        display="flex"
        height={64}
        sx={{
          borderBottom: "1px solid",
          borderColor: (t) => t.palette.divider,
          alignItems: "center",
          justifyContent: "space-between",
          px: 2,
          flexShrink: 0,
        }}
      >
        <Typography variant="subtitle1" flex={1}>
          {title}
        </Typography>
        {!permanent && (
          <IconButton onClick={onClose} sx={{ flexShrink: 0 }}>
            <Close />
          </IconButton>
        )}
      </Box>
    )}
    {(open || permanent) && <>{children}</>}
  </Box>
);

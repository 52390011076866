import {
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Skeleton,
} from "@mui/material";
import { FC } from "react";

const PlayerListItemLoader: FC<{ compact?: boolean }> = ({ compact }) => {
  return (
    <ListItem divider disablePadding>
      <ListItemButton>
        <ListItemAvatar>
          <Skeleton variant="circular">
            <Avatar sx={{ ...(compact && { width: 32, height: 32 }) }} />
          </Skeleton>
        </ListItemAvatar>
        <ListItemText
          primary={<Skeleton animation="wave" height={24} width="20%" />}
          secondary={
            compact ? null : (
              <Skeleton animation="wave" height={20} width="50%" />
            )
          }
        />
      </ListItemButton>
    </ListItem>
  );
};

export default PlayerListItemLoader;

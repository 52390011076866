import { Player } from "../types";

const calculateTeamAverageScore = (players: Player[]): number => {
  // if one players rank is zero, omit that player and then calculate rank
  if (players.some((player) => player.rank === 0)) {
    const some = players.filter((player) => player.rank !== 0);
    return some.reduce((sum, player) => sum + player.rank, 0) / some.length;
  }

  return players.reduce((sum, player) => sum + player.rank, 0) / players.length;
};

export default calculateTeamAverageScore;

import { Container, Grid, Typography } from "@mui/material";
import React, { useEffect, useMemo } from "react";
import useMatches from "../../hooks/useMatches";
import MatchCompact from "./MatchCompact";
import MatchCompactLoader from "./MatchCopactLoader";
import { useHeader } from "../../providers/HeaderProvider";
import { useTranslation } from "react-i18next";
import { SportsSoccer } from "@mui/icons-material";
import { isBefore } from "date-fns";
import { MATCH_STATUSES } from "../../consts";
import CustomCard from "../../components/CustomCard/CustomCard";
import Page from "../../components/Page/Page";

const Matches = () => {
  const { t } = useTranslation();
  const { setTitle } = useHeader();
  const { matches, loading } = useMatches();

  useEffect(() => {
    setTitle(t("MANAGE_GAMES"));
  });

  const sortedCompletedMatches = useMemo(
    () =>
      loading
        ? []
        : [...matches]
            .filter((match) => match.status === MATCH_STATUSES.COMPLETED)
            .sort((a, b) => {
              if (isBefore(a.settings.date, b.settings.date)) {
                return 1;
              } else {
                return -1;
              }
            }),
    [matches, loading]
  );

  const sortedScheduledMatches = useMemo(
    () =>
      loading
        ? []
        : [...matches]
            .filter((match) => match.status === MATCH_STATUSES.SCHEDULED)
            .sort((a, b) => {
              if (isBefore(a.settings.date, b.settings.date)) {
                return 1;
              } else {
                return -1;
              }
            }),
    [matches, loading]
  );

  return (
    <Page>
      <Container
        maxWidth="xl"
        sx={{ pt: 2, pb: 2, height: "100%", overflowY: "auto" }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <CustomCard
              title={t("SCHEDULED_GAMES")}
              icon={<SportsSoccer sx={{ mr: 1 }} />}
            />
          </Grid>
          {loading &&
            Array.from({ length: 3 }).map((_, key) => {
              return (
                <Grid item xs={12} md={4} key={key}>
                  <MatchCompactLoader />
                </Grid>
              );
            })}
          {sortedScheduledMatches?.map((match, key) => {
            return (
              <Grid item xs={12} md={4} key={match.documentId}>
                <MatchCompact
                  id={match.documentId}
                  {...match.settings}
                  variant={match.status}
                />
              </Grid>
            );
          })}
          {sortedScheduledMatches?.length === 0 && (
            <Grid item xs={12}>
              <Typography color="lightgray">
                {t("NO_SCHEDULED_GAMES")}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            <CustomCard
              title={t("GAME_HISTORY")}
              icon={<SportsSoccer sx={{ mr: 1 }} />}
            />
          </Grid>
          {loading &&
            Array.from({ length: 3 }).map((_, key) => {
              return (
                <Grid item xs={12} md={4} key={key}>
                  <MatchCompactLoader />
                </Grid>
              );
            })}
          {sortedCompletedMatches?.map((match, key) => {
            return (
              <Grid item xs={12} md={4} key={match.documentId}>
                <MatchCompact
                  id={match.documentId}
                  {...match.settings}
                  variant={match.status}
                />
              </Grid>
            );
          })}
          {sortedCompletedMatches?.length === 0 && (
            <Grid item xs={12}>
              <Typography color="lightgray">{t("NO_GAMES_HISTORY")}</Typography>
            </Grid>
          )}
        </Grid>
      </Container>
    </Page>
  );
};

export default Matches;

import Box from "@mui/material/Box";
import MobileStepper from "@mui/material/MobileStepper";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { useTranslation } from "react-i18next";
import { usePlayers } from "../../providers/PlayersProvider";
import SelectPlayers from "./steps/SelectPlayers/SelectPlayers";
import { FC, useCallback, useMemo, useState } from "react";
import MatchSettings from "./steps/MatchSettings/MatchSettings";
import { Formik } from "formik";
import { matchSettingsValidationSchema } from "./steps/MatchSettings/validationSchema";
import { MatchDraft, SelectedPlayers } from "../../types";
import { selectPlayersValidationSchema } from "./steps/SelectPlayers/validationSchema";
import MatchEditorValidator from "./MatchEditorValidator";
import { Container } from "@mui/material";
import MatchCompactLoader from "../../routes/Matches/MatchCopactLoader";
import CreateTeams from "./steps/CreateTeams/CreateTeams";
import { createTeamsValidationSchema } from "./steps/CreateTeams/validationSchema";
import Page from "../Page/Page";

type MatchEditorProps = {
  initialValues: MatchDraft;
  onSubmit: (values: MatchDraft) => Promise<void>;
  loading?: boolean;
  edit?: boolean;
};

const MatchEditor: FC<MatchEditorProps> = ({
  initialValues,
  onSubmit,
  edit,
  loading: loadingFromProp,
}) => {
  const { players, loading: loadingPlayers } = usePlayers();
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(0);

  const loading = loadingFromProp || loadingPlayers;

  const steps = useMemo(() => {
    return [
      {
        label: t("MATCH_SETTINGS"),
      },
      {
        label: t("SELECT_PLAYERS"),
      },
      {
        label: t("GENERATE_PLAYLIST"),
      },
    ];
  }, [t]);

  const maxSteps = steps.length;

  const handleNext = useCallback(() => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }, []);

  const handleBack = useCallback(() => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }, []);

  const getActualPlayers = useCallback(
    (selectedPlayers: SelectedPlayers) => {
      return loading
        ? []
        : selectedPlayers
            .map(
              (selectedPlayer) =>
                players?.find(
                  (player) => player.documentId === selectedPlayer.value
                )!
            )
            .sort((a, b) => b.rank - a.rank);
    },
    [players, loading]
  );

  const validationSchemas = [
    matchSettingsValidationSchema,
    selectPlayersValidationSchema,
    createTeamsValidationSchema,
  ];

  const isLastStep = activeStep === maxSteps - 1;

  if (loading) {
    return (
      <Page>
        <Container
          sx={{
            mt: 2,
          }}
        >
          <MatchCompactLoader />
        </Container>
      </Page>
    );
  }

  return (
    <Formik<MatchDraft>
      validateOnMount
      enableReinitialize
      validateOnBlur
      validationSchema={validationSchemas[activeStep]}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, isValid, values, setFieldValue, validateForm }) => {
        return (
          <Page>
            <MatchEditorValidator
              activeStep={activeStep}
              validateForm={validateForm}
            />
            <Box
              sx={{
                width: "100%",
                position: "relative",
                display: "flex",
                flexDirection: "column",
                height: "100%",
              }}
            >
              <Box
                sx={{
                  flex: 1,
                  display: "flex",
                  width: "100%",
                  height: "calc(100% - 48px)",
                }}
              >
                {activeStep === 0 && <MatchSettings />}
                {activeStep === 1 && (
                  <SelectPlayers
                    players={players}
                    loading={loading}
                    selectedPlayers={values.selectedPlayers}
                    onSelectPlayers={async (selectedPlayers) => {
                      await setFieldValue("selectedPlayers", selectedPlayers);
                      await setFieldValue("teams", undefined);
                    }}
                  />
                )}
                {activeStep === 2 && (
                  <CreateTeams
                    players={getActualPlayers(values.selectedPlayers!)}
                  />
                )}
              </Box>
              <MobileStepper
                variant="dots"
                steps={maxSteps}
                position="static"
                activeStep={activeStep}
                sx={{
                  width: "100%",
                  borderTop: "1px solid",
                  borderColor: (t) => t.palette.divider,
                }}
                nextButton={
                  <Button
                    size="small"
                    onClick={isLastStep ? () => handleSubmit() : handleNext}
                    disabled={!isValid || loading}
                  >
                    {isLastStep ? t("SAVE") : t("NEXT")}
                    <KeyboardArrowLeft />
                  </Button>
                }
                backButton={
                  <Button
                    size="small"
                    onClick={handleBack}
                    disabled={activeStep === 0}
                  >
                    <KeyboardArrowRight />
                    {t("BACK")}
                  </Button>
                }
              />
            </Box>
          </Page>
        );
      }}
    </Formik>
  );
};

export default MatchEditor;

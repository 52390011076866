import { collection, query } from "firebase/firestore";
import { FC, ReactNode, createContext, useContext, useMemo } from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import { db } from "../hooks/firebase";
import collectionToArray from "../utils/collectionToArray";
import { Player } from "../types";

export type PlayersContextType = {
  players?: Player[];
  loading?: boolean;
};

const PlayersContext = createContext<PlayersContextType>({});

export const usePlayers = () => {
  return useContext(PlayersContext);
};

const PlayersProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [playersSnapshot, loading] = useCollection(
    query(collection(db, "players")),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  const players = useMemo(
    () =>
      loading ? [] : collectionToArray<Player>({ collection: playersSnapshot }),
    [playersSnapshot, loading]
  );

  return (
    <PlayersContext.Provider value={{ players, loading }}>
      {children}
    </PlayersContext.Provider>
  );
};

export default PlayersProvider;

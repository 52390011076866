import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Chip } from "@mui/material";
import { PLAYER_POSITION, PlayerPosition } from "../../consts";
import { blue, red, grey, yellow } from "@mui/material/colors";

type PositionChipProps = {
  position: PlayerPosition;
};

export const mapPositionToColor = {
  [PLAYER_POSITION.DEFAULT]: grey[600],
  [PLAYER_POSITION.GOALIE]: yellow[900],
  [PLAYER_POSITION.DEFENSE]: blue[900],
  [PLAYER_POSITION.OFFENSE]: red[900],
};

export const PositionChip: FC<PositionChipProps> = ({ position }) => {
  const { t } = useTranslation();
  return (
    <Chip
      sx={{ bgcolor: mapPositionToColor[position], mr: 1 }}
      size="small"
      label={t(position)}
    />
  );
};

import { Avatar, Box, Card, CardContent, Skeleton } from "@mui/material";
import { FC } from "react";

const MatchCompactLoader: FC = () => {
  return (
    <Card>
      <CardContent>
        <Box display="flex" alignItems="center">
          <Skeleton variant="circular" sx={{ mr: 1 }}>
            <Avatar sx={{ width: 24, height: 24 }} />
          </Skeleton>
          <Skeleton animation="wave" width="35%" />
        </Box>
        <Box display="flex" alignItems="center" sx={{ mt: 1 }}>
          <Skeleton animation="wave" width="25%" />
        </Box>
      </CardContent>
    </Card>
  );
};

export default MatchCompactLoader;

import { addDoc, collection, deleteDoc, doc, setDoc } from "firebase/firestore";
import { Player } from "../types";
import { db } from "../hooks/firebase";

export const deletePlayer = async (playerId: string) => {
  const ref = doc(db, "players", playerId);

  try {
    await deleteDoc(ref);
  } catch (error) {
    console.log(error);
    // return apiError(error);
  }
};

export const addPlayer = async (values: Player) => {
  try {
    await addDoc(collection(db, "players"), {
      ...values,
      createdAt: Date.now(),
      lastUpdated: Date.now(),
    });
  } catch (error) {
    // return apiError(error);
  }
};

export const updatePlayer = async (
  playerId: string,
  values: Partial<Player>
) => {
  const ref = doc(db, "players", playerId);

  try {
    await setDoc(
      ref,
      {
        ...values,
        lastUpdated: Date.now(),
      },
      { merge: true }
    );
  } catch (error) {
    // return apiError(error);
  }
};

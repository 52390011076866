import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  FC,
} from "react";
import {
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  type User,
} from "firebase/auth";
import { auth } from "../hooks/firebase";

type InternalUser = User | null;
type SignInResponse =
  | {
      success: false;
      severity: string;
      message: string;
    }
  | {
      success: true;
      user: User;
    }
  | null;

type UserContextType = {
  loading: boolean;
  user: InternalUser;
  signin: (email: string, password: string) => Promise<SignInResponse>;
  signout: () => Promise<void>;
};

export const UserContext = createContext<UserContextType>({
  user: null,
  loading: false,
  signin: () => Promise.resolve(null),
  signout: () => Promise.resolve(),
});

const useUserManager = (): UserContextType => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<InternalUser>(null);

  const signin = async (
    email: string,
    password: string
  ): Promise<SignInResponse> => {
    try {
      const res = await signInWithEmailAndPassword(auth, email, password);

      return {
        success: true,
        user: res.user,
      };
    } catch (error) {
      return {
        success: false,
        severity: "error",
        message: "Wrong credentials, please try again.",
      };
    }
  };

  const signout = () => {
    return signOut(auth).then(() => {
      console.log({
        severity: "warning",
        message: "Logged out successfully.",
      });

      setUser(null);
    });
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }

      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  return {
    loading,
    user,
    signin,
    signout,
  };
};

export const useUser = () => {
  return useContext(UserContext);
};

const UserProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  const auth = useUserManager();

  return <UserContext.Provider value={auth}>{children}</UserContext.Provider>;
};

export default UserProvider;

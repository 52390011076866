const en = {
  AUTHENTICATE: "Authenticate",
  EMAIL: "Email",
  PASSWORD: "Password",
  SEARCH_PLAYER: "Search Player",
  SEARCH_PLAYER_HELPER: "{{count}} players found",
  NEXT: "Next",
  BACK: "Back",

  // POSITIONS
  OFFENSE: "Offense",
  DEFENSE: "Defense",
  GOALIE: "Goalie",
  DEFAULT: "General Field",

  // SIDE NAV
  MANAGE_GAMES: "My Games",
  MANAGE_PLAYERS: "My Players",
  SYSTEM: "System",
  SETTINGS: "Settings",
  LOGOUT: "Logout",

  //FABS
  ADD_PLAYER: "Add Player",
  EDIT_PLAYER: "Edit Player",
  DELETE_PLAYER: "Delete Player",

  // PLAYER
  PLAYER_NAME: "Name",
  PLAYER_POSITION: "Position",
  PLAYER_RANK: "Rank",
  PLAYER_ENTRIES: "Entries",
  PLAYERS: "Players",

  AVERAGE_RANK: "Average Rank",

  ASSIGN_PLAYERS: "Assign Players",
  N_PLAYERS_UNASSIGNED: "{{n}} unassigned players",
  N_OF_MATCHES: "{{count}} matches",
  DRAG_PLAYERS_HERE: "Drag players here",

  // MATCHES
  CREATE_MATCH: "Create Match",
  SELECT_PLAYERS: "Select Players",
  GENERATE_PLAYLIST: "Generate Teams",
  TEAM_TITLE: "Team #{{index}}",
  POWERS: "Powers",
  DRAG_AND_DROP: "You can drag and drop players between teams",
  QUICK_SHUFFLE: "Quick Shuffle",
  SELECT_PLAYERS_DECSRIPTION:
    "Select the players who will participate on game day",
  CREATE_MATCH_DAY: "Create Match",

  HOME: "Home",

  LOADING_APP: "Loading...",

  TODAY: "Today",

  COPIED_TO_CLIPBOARD: "Share link copied",

  DATA_COPIED: "Data copied successfully",
  PLAYERS_DATA_COPIED_TO_CLIPBOARD: "Entry report",

  PLAYER_TEMPORARY: "Temporary Player",
  SHARE_ENTRIES: "Share Entries",

  N_OF_PLAYERS: "{{count}} players",

  PLAYER_NAME_AND_ENTRIES: "{{name}}: *{{entries}}* entries",
  ENTRIES_DEBT: "Debts",
  MANAGER_REPORT: "Public Report",

  STATUS_SCHEDULED: "Game scheduled",
  STATUS_COMPLETED: "Game completed",

  // HOME
  WELCOME_BACK: "Welcome back, {{name}}",
  WELCOME_NO_MATCHES: "No games scheduled at the moment",

  MATCHMAKER: "Matchmaker",

  MATCH_SETTINGS: "Match Settings",
  MATCH_SETTINGS_DESCRIPTION:
    "Here you can set the time, location, and other settings for the game",

  SELECT_DATE: "Select Date",
  SELECT_TIME: "Select Time",
  MATCH_DATE: "Match Date",
  MATCH_TIME: "Match Time",
  NUMBER_OF_TEAMS: "Number of Teams",
  NUMBER_OF_PLAYERS_PER_TEAM: "Number of Players per Team",
  MATCH_LOCATION: "Match Location",
  REVIEW_AND_PUBLISH: "Review and Save",

  SAVE: "Save",
  MATCH_SAVED: "Match saved successfully",
  MATCH_SAVED_DESCRIPTION:
    "The match was saved successfully. You can find the match in your management page",

  NUMBER_OF_PLAYERS: "Number of Players",
  EDIT_MATCH: "Edit Match",
  TOTAL_NUMBER_OF_PLAYERS: "Total Players",
  MATCH: "Match",

  WELCOME_MATCHES: "You have {{numberOfMatches}} scheduled matches",
  NEXT_MATCH: "Next Match",
  MATCH_HISTORY: "Match History",
  MORE_MATCHES: "More Matches",

  REQUIRED: "Required field",
  MIN_PLAYERS: "You have selected too few players for the game",
  MAX_PLAYERS: "You have selected too many players for the game",

  EDIT_MATCH_DAY: "Edit Game",

  SHARE: "Share",
  DELETE: "Delete",
  EDIT: "Edit",
  COMPLETE_MATCH: "Complete Match",

  COMPLETE_MATCH_TITLE: "Do you want to complete the game?",
  COMPLETE_MATCH_DESCRIPTION:
    "Completing the game will update the system and the players' entries will be updated accordingly",

  DELETE_MATCH_TITLE:
    "Are you sure you want to delete the game at {{location}}?",
  DELETE_MATCH_DESCRIPTION:
    "The game will be permanently deleted and cannot be recovered",

  CONFIRM: "Confirm",
  CANCEL: "Cancel",

  MATCH_DAY: "Game Day",

  DELETE_PLAYER_CONFIRMATION:
    "Are you sure you want to delete the player? This action cannot be undone",

  DARK_MODE: "Dark Mode",
  DARK_MODE_HELPER_TEXT: "Enable or disable dark mode",

  EDIT_BUDGET: "Edit Budget",
  EDIT_BUDGET_HELPER_TEXT: "Here you can enter the budget in the team's fund",

  EDIT_MANAGER_SETTINGS_BUDGET: "Edit Budget",
  MANAGER_BUDGET: "Budget",
  MANAGER_BUDGET_HELPER_TEXT: "Enter your budget in the team fund",

  TEAMS: "Teams",

  PLAYER_NO_ENTRIES: "No entries",
  PLAYER_ENTRIES_LEFT: "{{entries}} entries left",
  PLAYER_MINUS_ENTRIES: "Owes {{entries}} entries",

  CHANGELOG: "Changelog",
  LATEST_VERSION: "Latest version: v{{v}}",

  GAME_CANCELED: "Game canceled",
  GAME_HISTORY: "Game History",
  SCHEDULED_GAMES: "Scheduled Games",
  NO_SCHEDULED_GAMES: "No future games found",
  NO_GAMES_HISTORY: "No games found in history",

  TROUBLE_MAKERS: "Players with debts",
  YOU_CAN_CHILL_FOR_NOW: "Players are okay",

  SEARCH: "Search",
  COMMON_ACTIONS: "Common Actions",
};

export default en;

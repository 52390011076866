import { useSortable } from "@dnd-kit/sortable";

import { CSS } from "@dnd-kit/utilities";
import { FC } from "react";
import PlayerListItem, {
  PlayerListItemProps,
} from "../../../PlayersList/PlayerListItem";
import { Box } from "@mui/material";

const SortablePlayerListItem: FC<PlayerListItemProps> = ({
  documentId,
  ...rest
}) => {
  const { attributes, listeners, setNodeRef, transform, transition, active } =
    useSortable({ id: documentId });

  return (
    <Box
      ref={setNodeRef}
      sx={{
        transform: CSS.Transform.toString(transform),

        transition,
        "--translate-x": transform ? `${Math.round(transform.x)}px` : undefined,
        "--translate-y": transform ? `${Math.round(transform.y)}px` : undefined,
        "--scale-x": transform?.scaleX ? `${transform.scaleX}` : undefined,
        "--scale-y": transform?.scaleY ? `${transform.scaleY}` : undefined,
      }}
    >
      <Box {...attributes} {...listeners}>
        <PlayerListItem
          {...rest}
          documentId={documentId}
          isActive={active?.id === documentId}
        />
      </Box>
    </Box>
  );
};

export default SortablePlayerListItem;

import { Button, DialogContent, DialogTitle, Grid } from "@mui/material";
import { Field, Form, Formik } from "formik";
import TextField from "../TextField/TextField";
import { useTranslation } from "react-i18next";
import { FC } from "react";
import { ManagerSettings } from "../../types";

export type ManagerSettingsDisplayType = "budget";

type ManagerSettingsEditorProps = {
  onSubmit: (values: Partial<ManagerSettings>) => void;
  initialValues: Partial<ManagerSettings>;
  loading?: boolean;
  disabled?: boolean;
  onDelete?: () => void;
  display: ManagerSettingsDisplayType;
};

const ManagerSettingsEditor: FC<ManagerSettingsEditorProps> = ({
  onSubmit,
  initialValues,
  loading,
  disabled,
  display,
}) => {
  const { t } = useTranslation();

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, isValid, dirty, values }) => {
        return (
          <>
            <DialogTitle>
              {t(`EDIT_MANAGER_SETTINGS_${display.toUpperCase()}`)}
            </DialogTitle>
            <DialogContent
              style={{
                width: 400,
                overflow: "hidden",
                maxWidth: "100%",
                padding: "8px 24px 24px 24px",
              }}
            >
              <Form>
                <Grid container spacing={2}>
                  {display === "budget" && (
                    <Grid item xs={12}>
                      <Field
                        // error={getFieldError(errors, touched, 'allowance')}
                        component={TextField}
                        name="budget"
                        type="number"
                        disabled={loading || disabled}
                        label={t("MANAGER_BUDGET")}
                        helperText={t("MANAGER_BUDGET_HELPER_TEXT")}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      onClick={() => handleSubmit()}
                      disabled={!dirty || !isValid || loading || disabled}
                    >
                      {t("EDIT")}
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            </DialogContent>
          </>
        );
      }}
    </Formik>
  );
};

export default ManagerSettingsEditor;

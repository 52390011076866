import {
  Box,
  Button,
  Divider,
  Grid,
  Hidden,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { usePlayers } from "../../providers/PlayersProvider";
import { Outlet, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useHeader } from "../../providers/HeaderProvider";
import { Player } from "../../types";
import PlayersActions from "./PlayersActions";
import { useNotification } from "../../providers/NotificationsProvider";
import Page from "../../components/Page/Page";
import { Add, Share } from "@mui/icons-material";
import { PlayersTable } from "../../components/PlayersTable/PlayersTable";
import { Panel } from "../../components/Panel/Panel";

const Players = () => {
  const theme = useTheme();
  const { setNotification } = useNotification();
  const [search, setSearch] = useState<string>("");
  const navigate = useNavigate();
  const { loading, players } = usePlayers();
  const { t } = useTranslation();

  const { setTitle } = useHeader();

  useEffect(() => {
    setTitle(t("MANAGE_PLAYERS"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filteredPlayers = useMemo(() => {
    const sortByName = (p: Player[] | undefined) =>
      p?.sort((a, b) => a.name.localeCompare(b.name, "he"));

    if (!search) {
      return sortByName(players);
    }

    return sortByName(
      players?.filter((player) =>
        player.name.toLowerCase().includes(search.toLowerCase())
      )
    );
  }, [search, players]);

  const copyPlayerNameAndEntriesToClipboard = useCallback(() => {
    if (!players) {
      return;
    }

    const playersData = players
      ?.filter((p) => !p.temporary)
      .sort((a, b) => {
        return (a.entries || 0) < (b.entries || 0) ? -1 : 1;
      })
      ?.map((player) => {
        const { name, entries } = player;
        return t("PLAYER_NAME_AND_ENTRIES", { name, entries: entries || "0" });
      });

    navigator.clipboard.writeText(playersData!.join("\n"));

    setNotification({
      title: t("DATA_COPIED"),
      body: t("PLAYERS_DATA_COPIED_TO_CLIPBOARD"),
    });
  }, [players, t, setNotification]);

  const onAddPlayer = useCallback(() => navigate("/players/add"), [navigate]);

  return (
    <Page>
      <Box display="flex" height="100%" width="100%" flex={1}>
        <Hidden lgDown>
          <Panel hideHeader permanent>
            <Grid container spacing={2} sx={{ p: 2 }}>
              <Grid item xs={12}>
                <Typography variant="h6">{t("SEARCH")}</Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  label={t("SEARCH_PLAYER")}
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">{t("COMMON_ACTIONS")}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  fullWidth
                  startIcon={<Add />}
                  onClick={onAddPlayer}
                >
                  {t("ADD_PLAYER")}
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  fullWidth
                  color="inherit"
                  startIcon={<Share />}
                  onClick={copyPlayerNameAndEntriesToClipboard}
                >
                  {t("SHARE_ENTRIES")}
                </Button>
              </Grid>
            </Grid>
          </Panel>
        </Hidden>
        <Box
          display="flex"
          flexDirection="column"
          height="100%"
          width="100%"
          flex={1}
        >
          <Hidden lgUp>
            <Box p={2} height={96}>
              <TextField
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                label={t("SEARCH_PLAYER")}
                fullWidth
                size="small"
                helperText={t("SEARCH_PLAYER_HELPER", {
                  count: filteredPlayers?.length,
                })}
              />
            </Box>
          </Hidden>
          <Box
            sx={{
              height: "100%",
              width: "100%",
              p: 2,
              [theme.breakpoints.down("lg")]: {
                py: 0,
                px: 2,
                height: "calc(100% - 96px)",
              },
            }}
          >
            <PlayersTable
              players={filteredPlayers}
              loading={loading}
              onPlayerClick={(player) =>
                navigate(`/players/edit/${player.documentId}`)
              }
            />
          </Box>
        </Box>
      </Box>
      <Hidden lgUp>
        <PlayersActions
          onAddPlayer={onAddPlayer}
          onShare={copyPlayerNameAndEntriesToClipboard}
        />
      </Hidden>
      <Outlet context={{ setSearch }} />
    </Page>
  );
};

export default Players;

import get from "lodash.get";

const getFieldError = (
  errors: any,
  touched: any,
  fieldName: string,
  t: (key: string) => string,
  skipTouched = false
) => {
  const fieldError = get(errors, fieldName);
  const fieldTouched = get(touched, fieldName);

  if (skipTouched && fieldError) return t(fieldError);

  return fieldError && fieldTouched ? t(fieldError) : "";
};

export default getFieldError;
